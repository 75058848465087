import { Flex, Box, Text, Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer, Center, Input, useToast, Button,
    Heading,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Select} from '@chakra-ui/react'
import AXIOS from '../Config/axiosConfig'
import { useEffect, useState } from 'react'
import { ModalViewPagamento, SideBarMenu, NavBar, Loader, BotaoTop, DrawerFiltrosPagamentos } from '../Components/index'
import { Link, useNavigate } from 'react-router-dom'
import { MdArrowBack, MdArrowForward, MdDone, MdDoneAll, MdError, MdOutlineRemoveDone, MdUpgrade, MdWebhook} from "react-icons/md"
import ReactPaginate from 'react-paginate';
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { TbPigMoney } from 'react-icons/tb'


function Pagamentos() {

    const [referencias, setReferencias] = useState([])
    const [referenciasFilter, setReferenciasFilter] = useState([])
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [user, setUser] = useState('1')
    const [pagina, setPagina] = useState('1')
    const [paginas, setPaginas] = useState('1')
    const [quantidade, setQuantidade] = useState('100')
    const [loader, setLoader] = useState(false)
    const toast = useToast()
    const {t, i18n} = useTranslation()
    
    async function axiosReferencias(){
    
    try {
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      const referencia = await AXIOS.get(`/pagamentos/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`)

      console.log(referencia.data)
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
          setReferencias(referencia.data.mensagem)
          setReferenciasFilter(referencia.data.mensagem) 
          setPagina(referencia.data.registros.pagina_actual) 
          setPaginas(referencia.data.registros.paginas) 
          setQuantidade(referencia.data.registros.limite) 
        }
        
        setLoader(true)
          
      }catch (error) {
        toast({
          title: t('Alerta'),
          description: t("Não conseguimos ter comunicação! Verifique sua internet"),
          status: 'error',
        })
    }
    }

    const reenviarRequest = async (registo) => {

      toast({
        title: 'Enviar requisição',
        description: "Á processar",
        status: 'loading',
        duration: 1500,
        isClosable: true,
      })

      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade

      try {

        const dadosT = {
          entidade_aceite: entidade,
          dados: registo
        }
          
            const responseR = await AXIOS.post('/logs-requisicao/reenvio-forcado', dadosT);

            if(responseR.data.status == "sucesso"){
                setTimeout(()=>{
                  toast({
                    title: 'Enviar requisição',
                    description: responseR.data.mensagem, 
                    status: 'success',
                    duration: 1500,
                    isClosable: true,
                  })
      
                },1000)
                
            }else {
              setTimeout(()=>{
                toast({
                  title: 'Enviar requisição',
                  description: responseR.data.mensagem, 
                  status: 'error',
                  duration: 1500,
                  isClosable: true,
                })
            },1800)
            }
            
        }
        catch (error) {
          console.log("STATUS",error);
          setTimeout(()=>{
            toast({
              title: 'Reenviar requisição',
              description: error.message,
              status: 'error',
              duration: 2500,
              isClosable: true,
            })
        },2800)
        }
    }


  

    useEffect(()=>{
              axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
            navigate('/login')
          }
    },[])
    
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
  
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const itemsPerPage = 50
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = referenciasFilter.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % referenciasFilter.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };
    
    const selectdItem = (evt) =>{
      const tipo = evt.target.value.toLocaleLowerCase()
      const modified = referencias.map(item => {
        
        return {
          ...item,
          "num_referencia": item.num_referencia,
          "referencia_do_montante": item.referencia_do_montante,
          "data_limite_pagamento": item.data_limite_pagamento,
          "data_inicio_de_pagamento": item.data_inicio_de_pagamento,
          "codigo_de_processamento": item.codigo_de_processamento == 80 ? "Activo" : "Inactivo" ,
          "registo_produto": item.registo_produto
        }
        
      
      })
      const filtered = modified.filter(item => JSON.stringify(item).toLocaleLowerCase().replaceAll('T', ' ').replaceAll('Z', '').replaceAll('-','/').indexOf(tipo) > -1)
      setReferenciasFilter(filtered)
      
}

const actualizarPagamentos = async () => {
  try {
    setLoader(false)
    const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
    const referencia = await AXIOS.get(`/pagamentos/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`)
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem)  
      }
      
      setLoader(true)
        
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
};

const buscarComPaginacao  = async function(){
  try {
    setLoader(false)
    const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
    const referencia = await AXIOS.get(`/pagamentos/entidade/${entidade}/?limite=${quantidade}&pagina=${pagina}`)
    console.log(referencia.data)
    if(referencia.data.status === "erro")
        setReferencias([])
    else{
        setReferencias(referencia.data.mensagem)
        setReferenciasFilter(referencia.data.mensagem) 
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }
      
      setLoader(true)
        
    }catch (error) {
      toast({
        title: t('Alerta'),
        description: t("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  }
}

async function preencherNaTabela(referencia) {
  try {

    if (referencia.data.status === "erro") setReferencias([]);
    else {
      setReferencias(referencia.data.mensagem);
      setReferenciasFilter(referencia.data.mensagem);
      setPagina(referencia.data.registros.pagina_actual) 
      setPaginas(referencia.data.registros.paginas) 
      setQuantidade(referencia.data.registros.limite) 
    }

    
  } catch (error) {
    navigate("/sem-comunicacao");
  }
}

let permissao = user?.pagina_pagamento == "true";

if(user?.pagina_pagamento) permissao = user?.pagina_pagamento == "true"
else permissao = user.numero_entidade

  return (
  <>
    
    <Flex color='white' bg="white" direction="row">
      <SideBarMenu />
      <BotaoTop/>
      <Box flex={1}>
      <NavBar titulo={t("Meus pagamentos")} imagem={<TbPigMoney size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}/>
      <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
        {permissao? <Box px={10}>
            {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={10}>{t("Meus pagamentos")}</Text> */}
            <Flex alignItems="center" mt={5} justifyContent="space-between">
              <Flex color="gray.500" alignItems="center">
                <Text></Text> <Input w="xs" bg="white" type="search" placeholder={`${t('Pesquise')} ...`} onChange={selectdItem}/>
                <Button
                    bg="blue.400"
                    _hover={{ backgroundColor: "blue.600" }}
                    ml={1}
                    onClick={actualizarPagamentos}
                    leftIcon={<MdUpgrade />}
                    color="white"
                  >
                    {t("Actualizar")}
                  </Button>
                  <DrawerFiltrosPagamentos preencherNaTabela={preencherNaTabela} entidade={user.numero_entidade}/>
              </Flex>

              <Flex width="50%" color="gray.600" justifyContent="flex-end" alignItems="center"> 
                <NumberInput
                  name='pagina'
                  onChange={(valueString) => setPagina(parseInt(valueString))}
                  value={pagina}
                  max={paginas}
                  min={1}
                  width={120}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Select ml={1} width={200} name='quantidade' onChange={e => setQuantidade(e.target.value)} value={quantidade}>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                    <option value="5000">5000</option>
                    <option value="10000">10000</option>
                </Select>
                <Button ml={1} onClick={buscarComPaginacao} colorScheme='orange'>Buscar</Button>
              </Flex>
            </Flex>
            
          {loader ?
          <TableContainer mt={5} bg="white">
            <Table variant='striped' colorScheme='gray' boxShadow="md">
              <TableCaption>
                <ReactPaginate
                     breakLabel="..."
                     nextLabel={<MdArrowForward/>}
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={5}
                     pageCount={pageCount}
                     breakClassName="list-group-item"
                     previousLabel={<MdArrowBack/>}
                     renderOnZeroPageCount={null}
                      />
              </TableCaption>
              <Thead p={10}>
                <Tr bg="gray.600">
                  <Th py={6} color="gray.300">{t("Referência")}</Th>
                  <Th py={6} color="gray.300">{t("Montante")}</Th>
                  <Th py={6} color="gray.300">{t("Data do processamento")}</Th>
                  {/* <Th py={6} color="gray.300">Hora</Th> */}
                  <Th py={6} color="gray.300">{t("Produto")}</Th>
                  <Th py={6} color="gray.300">{t("Localidade")}</Th>
                  {user.habilitar_area_coluna_prt_tabela_pagamentos == "true" && <Th py={6} color="gray.300">{t("Notificação")}</Th>}
                  {user.habilitar_area_coluna_mft_tabela_pagamentos == "true" && <Th py={6} color="gray.300">{t("Comprovante")}</Th>}
                  {user.habilitar_area_coluna_concliacao_tabela_pagamentos == "true" && <Th py={6} color="gray.300">{t("Estado da concialiação")}</Th>}
                  <Th py={6} color="gray.300">{t("Acção")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map(el =>
                    <Tr color="blackAlpha.700">
                      <Td onClick={() => window.location.href = `/pagamentos/${el.referencia_do_servico}`} fontWeight={700} _hover={{textDecoration:'underline', cursor:"pointer", color:"#3294ed"}}>{el.referencia_do_servico}</Td>
                      <Td color="green.600" fontWeight={700}>AKZ {Intl.NumberFormat('PT-br').format(el.montante_da_operacao)}</Td>
                      <Td>{el.PRT == "ACEITE" ? new Date(el.data_movimento).toLocaleDateString() : new Date(el.data_do_processamento).toLocaleDateString()}</Td>
                      {/* <Td>{el.PRT == "ACEITE" ? new Date('2000-01-01 '+el.hora_do_movimento).toLocaleTimeString() : new Date(el.data_hora_transacao_cliente).toLocaleTimeString()}</Td> */}
                      <Td>{el.produto}</Td>
                      <Td>{el.localidade}</Td>
                      {user.habilitar_area_coluna_prt_tabela_pagamentos == "true" && <Td>{el.PRT == "ACEITE" ? <MdDoneAll size={26} color='green' /> : <MdOutlineRemoveDone size={26} color='red' />}</Td>}
                      {user.habilitar_area_coluna_mft_tabela_pagamentos == "true" && <Td>{el.MFT == "CONCLUIDO" ? <MdDoneAll size={26} color='green' /> : <MdOutlineRemoveDone size={26} color='red' />}</Td>}
                      {user.habilitar_area_coluna_concliacao_tabela_pagamentos == "true" && <Td fontWeight={700}>{el.estado_pagamento == "FINALIZADO" ? <span style={{color:"#090"}}>{el.estado_pagamento}</span> : el.estado_pagamento == "ANULADO" ? <span style={{color:"#900"}}>{el.estado_pagamento}</span> : <span style={{color:"#009"}}>{el.estado_pagamento}</span>}</Td>}
                      <Td>
                          <ModalViewPagamento registo={el}/>
                          <Button ml={2} size="sm"  colorScheme='blue' leftIcon={<MdWebhook/>} onClick={() => reenviarRequest(el)}>Ir no webhook</Button>
                      </Td>
                    </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          :
          <Center>
            {/* <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            /> */}
            <Loader />
          </Center>}
        </Box>
        :<Center width="100%">
        <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
            <MdError size={200} color="red"/>
            <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
            <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
        </Flex>
      </Center>}
        <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
        </Box>
      </Flex>
      </Box>
    </Flex>
  </>
  )
}

export default Pagamentos