import React, {useState} from 'react'
import { Button,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,Card, CardHeader, CardBody, Box, Heading, Text, Stack, StackDivider, Flex } from '@chakra-ui/react'
import { MdClose, MdSearch } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

function ModalViewReference({registo}) {
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const onOpen = () => {
            setOpen(!open)
    }
    
    return (
      <>
        <Button onClick={onOpen} size="sm" leftIcon={<MdSearch />} bg='blue.500' color="white" variant='solid'></Button>
  
        <Modal isOpen={open} onClose={onOpen} size="2xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>{t("Mais informações da referência")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Card>
                  <CardHeader>
                    <Heading size='md'>REF: {registo.num_referencia ? registo.num_referencia : registo.referencia_do_montante}</Heading>
                  </CardHeader>
                
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Tipo de registo")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {t(registo.registo_produto == "Pagamento/Carregamento" ? registo.tipo_referencia_pagamento == "P" ? "Pagamentos" : "Carregamentos" : registo.registo_produto)}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Indicador de produtos")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.produto}
                            </Text>
                          </Box>
                      </Flex>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Sua entidade")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {registo.entidade_cliente}
                        </Text>
                      </Box>
                      {(registo.tipo_referencia_pagamento == "P") && (registo.montante_fixo > 99)  && 
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante Fixo")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            AKZ {Intl.NumberFormat('PT-br').format(registo.montante_fixo)}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("ACeite para fixo")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {registo.aceitar_fixo}
                          </Text>
                        </Box>
                      </Flex>}
                      {(registo.tipo_referencia_pagamento == "P") && (registo.montante_limitado_para_pagamento_longo > 99)  && 
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante Limitado")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            AKZ {Intl.NumberFormat('PT-br').format(registo.montante_limitado_para_pagamento_longo)}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Pago")}
                          </Heading>
                          <Text pt='2' fontSize='sm' color="green.500" fontWeight={700}>
                            AKZ {Intl.NumberFormat('PT-br').format(registo.montante_limitado_para_pagamento_longo_pago)}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Por Faltar")}
                          </Heading>
                          <Text pt='2' fontSize='sm' color="red.500" fontWeight={700}>
                            AKZ {Intl.NumberFormat('PT-br').format(Number(registo.montante_limitado_para_pagamento_longo) - Number(registo.montante_limitado_para_pagamento_longo_pago))}
                          </Text>
                        </Box>
                      </Flex>}
                      {registo.tipo_referencia_pagamento == "I" && <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante minimo")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            AKZ {registo.montante_minimo ? Intl.NumberFormat('PT-br').format(registo.montante_minimo) : <MdClose size={20} color='red'/>}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante maximo")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            AKZ {registo.montante_maximo ? Intl.NumberFormat('PT-br').format(registo.montante_maximo) : <MdClose size={20} color='red'/>}
                          </Text>
                        </Box>
                      </Flex>}
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          {registo.tipo_referencia_pagamento == "I" &&  <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Data de inicio de pagamento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.data_inicio_de_pagamento ? new Date(registo.data_inicio_de_pagamento).toLocaleDateString() : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>}
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Hora limite de pagamento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.data_limite_pagamento ? new Date(registo.data_limite_pagamento).toLocaleDateString() : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          {(registo.tipo_referencia_pagamento == "P" || registo.tipo_referencia_pagamento == "L") &&<Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Hora limite de pagamento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.hora_limite_pagamento ? registo.hora_limite_pagamento : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>}
                      </Flex>
                      {registo.tipo_referencia_pagamento == "R" && <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Código de activação")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.codigo_de_ativacao ? registo.codigo_de_ativacao : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Chave de activação")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.chave_ativacao ? registo.chave_ativacao : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>}
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          {registo.tipo_referencia_pagamento == "I" && <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Código de cliente")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.codigo_de_cliente ? registo.codigo_de_cliente : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>}
                          {registo.tipo_referencia_pagamento == "R" && <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Número serie helpdesk")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.numero_serie_helpDesk ? registo.numero_serie_helpDesk : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>}
                      </Flex>
                      {registo.tipo_referencia_pagamento == "I" && <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Numeros de linhas")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.numero_de_linhas ? registo.numero_de_linhas : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Texto para o talão")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.textos_para_talao ? registo.textos_para_talao : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>}
                      {registo.tipo_referencia_pagamento == "R" && <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Data de validade")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {registo.data_de_validade ? new Date(registo.data_de_validade).toLocaleDateString() : <MdClose size={20} color='red'/> }
                        </Text>
                      </Box>}
                      {registo.opcional_1 && <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Dado adcional 1")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {String(registo.opcional_1.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_1.split('=')[1])})</b>
                        </Text>
                      </Box>}
                      {registo.opcional_2 && <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Dado adcional 2")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {String(registo.opcional_2.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_2.split('=')[1])})</b>
                        </Text>
                      </Box>}
                      {registo.opcional_3 && <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Dado adcional 3")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {String(registo.opcional_3.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_3.split('=')[1])})</b>
                        </Text>
                      </Box>}
                    </Stack>
                  </CardBody>
                </Card>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onOpen}>
                <MdClose/>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalViewReference
