import React, {useState, useEffect, useRef} from 'react'
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay,DrawerContent,Button, RadioGroup,
  Stack,
  Radio,FormControl, FormLabel, Input, Box, Text, Select, Textarea, Flex, useToast, Tooltip } from '@chakra-ui/react'
import {  MdAdd, MdPeople, MdUpdate} from "react-icons/md"
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

export default function PlacementExample({axiosReferenciass}) {
    const [placement] = useState('right')
    const [referencias, setReferencias] = useState([])
    const [produtos, setProdutos] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [tipo_re, setTipo_re] = useState(["Pagamento/Carregamento"])
    const [user, setUser] = useState('1')
    const [indicador_id, setIndicador_produto_id] = useState('1')
    const refREF = useRef(null)
    const refREFOPT1 = useRef(null)
    const refREFOPT2 = useRef(null)
    const refREFOPT3 = useRef(null)
    const refREFMontante = useRef(null)
    const refCodCli = useRef(null)
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const toast = useToast()
    const {t, i18n} = useTranslation()
    
    
    async function axiosReferencias(){
      try {
          const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
          
          const referencia = await AXIOS.get(`/registro-produtos-cliente/entidade/${entidade}`)
          if(referencia.data.status === "erro")
              setReferencias([])
          else{
              setReferencias(referencia.data.mensagem[0].registo)
          }

        }catch (error) {
          navigate('/sem-comunicacao')
      }
      
          
  }  

  const buscarProduto =  async (item) =>{ 
  
    try{
      const referencia = await AXIOS.get(`/produtos-clientes/produto/${item}`)
      if(referencia.data.status === "erro")
          setProdutos([])
        else{
          if(referencia.data.mensagem.length){
            setProdutos(referencia.data.mensagem[0].registo)
            console.log(referencia.data.mensagem[0].registo)
          }
          else
            setProdutos([])
        }

      }catch (error) {
        navigate('/sem-comunicacao')
    }
  }
    
  const onTipoRegisto = (value)=>{
      try{
        const valores = value.split(':')
        if(valores[1] === "1"){
          setTipo_re(["Pagamento/Carregamento"])
        }
        
        if(valores[1] === "2"){
          setTipo_re(["Recargas"])
        }
        
        if(valores[1] === "3"){
          setTipo_re(["Facturas"])
        }
        
        buscarProduto(valores[0])
        // refREF.current.setAttribute('value','')
      }catch (error) {
        navigate('/sem-comunicacao')
    }
  }
  
  const formik = useFormik({
    initialValues:{
      num_referencia:'',
      tipo_de_registro:'',
      referencia_do_montante:'',
      indicador_de_produtos: '',
      codigo_de_processamento: '80',
      textos_para_talao: '',
      quantidade_de_unidades: '',
      codigo_de_ativacao: '',
      numero_serie_helpDesk: '',
      chave_ativacao: '',
      data_de_validade: '',
      montante_maximo: '',
      montante_minimo: '',
      data_inicio_de_pagamento: '',
      codigo_de_cliente: '',
      numero_de_linhas: '',
      opcional_1: '',
      opcional_2: '',
      opcional_3: '',
      tipo_referencia_pagamento: '',
      gerado_por_afiliado:"",
      montante_fixo:"",
      montante_limitado_para_pagamento_longo:"" 
    },
    onSubmit: async (values)=> {
    
    

      if((/[a-zA-Z]{2,}=\w{2,}/ig.exec(values.opcional_1)) && values.opcional_1 != ""){
            refREFOPT1.current.style = "border:1px solid green";
      }else {
            formik.initialValues.opcional_1 = ""
            values.opcional_1 = "";
      }

      if((/[a-zA-Z]{2,}=\w{2,}/ig.exec(values.opcional_2)) && values.opcional_2 != ""){
            refREFOPT2.current.style = "border:1px solid green";
      }else {
            formik.initialValues.opcional_2 = ""
            values.opcional_2 = "";
      }

      if((/[a-zA-Z]{2,}=\w{2,}/ig.exec(values.opcional_3)) && values.opcional_3 != ""){
            refREFOPT3.current.style = "border:1px solid green";
      }else {
            formik.initialValues.opcional_3 = ""
            values.opcional_3 = "";
      }


      if(values.textos_para_talao){

        if(values.textos_para_talao.length > 40){
            toast({
              title: 'Gerar referência',
              description: "Tamanho de caracteres para o talão incorrecto",
              status: 'error',
              duration: 1500,
              isClosable: true,
            })
        }

      }

      if(values.tipo_de_registro == 1) {

        const find = produtos.find(value => value.id_produto == indicador_id)
        
        if(find.montantes_fixo == "SIM") values.tipo_referencia_pagamento = "L"

        if(find.montantes_fixo == "NÃO") values.tipo_referencia_pagamento = "P"
      }

      if(values.tipo_de_registro == 2) values.tipo_referencia_pagamento = "R"

      if(values.tipo_de_registro == 3) values.tipo_referencia_pagamento = "I"      

      if(user?.id_afiliado) values.gerado_por_afiliado = user?.id_afiliado
            

    
      const res =  await AXIOS.post(`/referencias`,{...values, gerado_por_afiliado: user?.id_afiliado || 0, entidade_cliente: user.numero_entidade, indicador_produto_id: indicador_id}) 

      toast({
        title: 'Gerar referência',
        description: "Á carregar o registro",
        status: 'loading',
        duration: 3000,
        isClosable: true,
      })
      
      if(res.data.status === "sucesso"){
        
        setTimeout(()=>{
          setIsOpen(false)
          formik.resetForm()
          refREF.current.value = ''
        },1000)
        
        
        
        setTimeout(()=>{
          toast({
            title: 'Gerar referência',
            description: res.data.mensagem,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          axiosReferenciass()
        },3500)
      }else{
        setTimeout(()=>{
          toast({
            title: 'Gerar referência',
            description: res.data.mensagem,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },5000)
      }
      
      axiosReferenciass() 
    },
  })
  
  const validarDigitos = (evt) =>{
    try{
      const keys = ["8","46","48","49","50","51","52","53","54","55","56","57"]
      const find = keys.find(key => key == evt.keyCode)
      if(!find) evt.target.value = evt.target.value.replace(evt.target.value.substr(-1),'')
    }catch (error) {
      navigate('/sem-comunicacao')
    }
      
  }

  const calcularCaracteresRestantes = () =>{
    try {
      const total = 40;
      const restante = total - formik.values.textos_para_talao.length
      return restante
    }catch (error) {
      navigate('/sem-comunicacao')
    }
  }
  
  const onClose = function(){
      setIsOpen(false)
  }

  useEffect(()=>{
    axiosReferencias();
    const value = JSON.parse(loginValue)

    if(value?.mensagem?.numero_entidade){
      setUser(value.mensagem)
    }
    axiosReferencias()
      
  },[])

    return (
      <>
        <Button leftIcon={<MdAdd/>} bg='blue.400' _hover={{backgroundColor:"blue.600"}} color="white" onClick={()=>setIsOpen(true)}>
          {t("Adcionar")}
        </Button>
        <Drawer placement={placement} onClose={onClose} isOpen={isOpen} size="xl">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader borderBottomWidth='1px'>{t("Criar referências")}</DrawerHeader>
            <DrawerBody>
              <form onSubmit={formik.handleSubmit}>
                  <Box bg='white'>
                    {(tipo_re.includes("Pagamento/Carregamento") || tipo_re.includes("Facturas")) && <FormControl mt={4}>
                        <FormLabel>{t("Referência")}</FormLabel>
                        <Flex direction="row" alignItems="center">
                          <Input type='text' onKeyUp={validarDigitos} name='num_referencia' minLength={user.num_padrao_referencias} maxLength={user.num_padrao_referencias} required ref={refREF}
                          onChange={formik.handleChange}
                         value={formik.values.num_referencia} />
                         <Button ml={1} bg="gray.500" _hover={{backgroundColor:"gray.700"}} color="white" onClick={() => {refREF.current.value = ((Math.random() * 1000) + 1).toString().replace('.', '').substring(0,user.num_padrao_referencias); formik.values.num_referencia = refREF.current.value} } leftIcon={<MdUpdate/>} type='button' >{t("Gerar")}</Button>
                        </Flex>
                    </FormControl>}
                    {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                        <FormLabel>{t("Referência do montante")}</FormLabel>
                        <Flex direction="row" alignIterems="center">
                          <Input type='text' name='referencia_do_montante'  required onKeyUp={validarDigitos} ref={refREFMontante} minLength={1} maxLength={5}
                            onChange={formik.handleChange}
                           value={formik.values.referencia_do_montante} />
                           <Button bg="gray.500" _hover={{backgroundColor:"gray.600"}} ml={1} color="white" onClick={() => {refREFMontante.current.value =((Math.random() * 1000) + 1).toString().replace('.', '').substring(0,5); formik.values.referencia_do_montante = refREFMontante.current.value} } leftIcon={<MdUpdate/>} type='button' >{t("Gerar")}</Button>
                        </Flex>
                    </FormControl>}
                    {!formik.values.montante_limitado_para_pagamento_longo && (produtos.find(value => (value.id_produto == indicador_id))?.montantes_fixo == "NÃO" && formik.values.tipo_de_registro == "1") && <FormControl mt={2}>
                        <FormLabel>{t("Montante fixo")}</FormLabel>
                        <Flex direction="row" alignIterems="center">
                          <Input type='number' name='montante_fixo' min={100} max={19999999999}
                            onChange={formik.handleChange}
                           value={formik.values.montante_fixo} />
                        </Flex>
                    </FormControl>}
                    {!formik.values.montante_fixo && (produtos.find(value => (value.id_produto == indicador_id))?.montantes_fixo == "NÃO" && formik.values.tipo_de_registro == "1") && <FormControl mt={2}>
                        <FormLabel>{t("Montante limitado")}</FormLabel>
                        <Flex direction="row" alignIterems="center">
                          <Input type='number' name='montante_limitado_para_pagamento_longo' min={100} max={19999999999}
                            onChange={formik.handleChange}
                           value={formik.values.montante_limitado_para_pagamento_longo} />
                        </Flex>
                    </FormControl>}
                    <FormControl mt={2}>
                          <FormLabel>{t("Como padrão")}</FormLabel>
                          <Select required name='codigo_de_processamento' onChange={formik.handleChange} value={formik.values.codigo_de_processamento}>
                            <option value="80">{t("Activada")}</option>
                            <option value="82">{t("Desactivada")}</option>
                        </Select>
                    </FormControl>
                    <FormControl mt={2}>
                        <FormLabel>{t("Para qual tipo")}</FormLabel>
                        <Select placeholder={t('Selecione o tipo de produto')} required name='tipo_de_registro' value={formik.values.tipo_de_registro} onChange={(evt) =>{formik.handleChange(evt);onTipoRegisto(evt.target.options.item(evt.target.options.selectedIndex).getAttribute('id'))}}>
                        {referencias.map(el => <option id={el.id_tipo_produto_clientes + ":" + el.id_tipo_produto} value={el.id_tipo_produto}>{t(el.registo_produto)}</option>)}
                        
                      </Select>  
                    </FormControl> 
                    <FormControl mt={2}>
                        <FormLabel>{t("Indica seu produto")}</FormLabel>
                        <Select placeholder={t('Selecione o seu produto para referência')} required name='indicador_de_produtos' value={formik.values.indicador_de_produtos} onChange={(evt)=>{setIndicador_produto_id(evt.target.options.item(evt.target.options.selectedIndex).getAttribute('id'));formik.handleChange(evt);}}>
                        {produtos.map(el => <option id={el.id_produto} value={el.codigo_do_produto}>{el.produto}</option>)}
                      </Select>
                    </FormControl>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                      {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                          <FormLabel>{t("Data de inicio de pagamento")}</FormLabel>
                          <Input type='date' name='data_inicio_de_pagamento' required
                            onChange={formik.handleChange}
                           value={formik.values.data_inicio_de_pagamento} />
                      </FormControl>}
                      {(tipo_re.includes("Pagamento/Carregamento") || tipo_re.includes("Facturas")) && <FormControl mt={2} ml={1}>
                          <FormLabel>{t("Data limite de pagamento")}</FormLabel>
                          <Input type='date' name='data_limite_pagamento'  required
                            onChange={formik.handleChange}
                           value={formik.values.data_limite_pagamento} />
                      </FormControl>}
                      {tipo_re.includes("Pagamento/Carregamento") && <FormControl mt={2} ml={1}>
                          <FormLabel>{t("Hora limite de pagamento")}</FormLabel>
                          <Input type='time' name='hora_limite_pagamento'
                            onChange={formik.handleChange}
                           value={formik.values.hora_limite_pagamento} />
                      </FormControl>}
                    </Flex>
                    {/* {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                        <FormLabel>Codigo do cliente</FormLabel>
                        <Input type='text' name='codigo_de_cliente' onKeyUp={validarDigitos} minLength={1} maxLength={10} required
                          onChange={formik.handleChange}
                         value={formik.values.codigo_de_cliente} />
                    </FormControl>} */}
                    {(tipo_re.includes("Recargas")) &&  <FormControl mt={2}>
                        <FormLabel>{t("Quantidade de unidades")}</FormLabel>
                        <Input type='text' name='quantidade_de_unidades' onKeyUp={validarDigitos} maxLength={5} minLength={1}  required
                          onChange={formik.handleChange}
                         value={formik.values.quantidade_de_unidades} />
                    </FormControl>}
                    {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                        <FormLabel>{t("Número de serie Helpdesk")}</FormLabel>
                        <Input type='text' name='numero_serie_helpDesk' onKeyUp={validarDigitos} minLength={1} maxLength={10}  required
                          onChange={formik.handleChange}
                         value={formik.values.numero_serie_helpDesk} />
                    </FormControl>}
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                    {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                        <FormLabel>{t("Código de activação")}</FormLabel>
                        <Input type='text' onKeyUp={validarDigitos} minLength={1} maxLength={5}  name='codigo_de_ativacao'  required
                          onChange={formik.handleChange}
                         value={formik.values.codigo_de_ativacao} />
                    </FormControl>}
                    {(tipo_re.includes("Recargas")) && <FormControl ml={1} mt={2}>
                        <FormLabel>{t("Chave de activação")}</FormLabel>
                        <Input type='text' name='chave_ativacao' onKeyUp={validarDigitos} minLength={4} maxLength={32}  required
                          onChange={formik.handleChange}
                         value={formik.values.chave_ativacao} />
                    </FormControl>}
                    </Flex>
                    {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                        <FormLabel>{t("Data de validade")}</FormLabel>
                        <Input type='date' name='data_de_validade'  required
                          onChange={formik.handleChange}
                         value={formik.values.data_de_validade} />
                    </FormControl>}
                    {(tipo_re.includes("Facturas")) &&  <FormControl mt={2}>
                        <FormLabel>{t("Código de cliente")}</FormLabel>
                        <Flex direction="row" alignIterems="center">                          
                          <Input type='text' name='codigo_de_cliente' onKeyUp={validarDigitos} minLength={3} maxLength={10}  required ref={refCodCli}
                            onChange={formik.handleChange}
                           value={formik.values.codigo_de_cliente} />
                           <Button bg="gray.500" _hover={{backgroundColor:"gray.700"}} ml={1} color="white" onClick={() => {refCodCli.current.value = ((Math.random() * 1000) + 1).toString().replace('.', '').substring(0,5); formik.values.codigo_de_cliente = refCodCli.current.value} } leftIcon={<MdUpdate/>} type='button' >{t("Gerar")}</Button>
                        </Flex>
                    </FormControl>}
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                        {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                            <FormLabel>{t("Montante minimo")}</FormLabel>
                            <Input type='number' name='montante_minimo'  required
                              onChange={formik.handleChange}
                             value={formik.values.montante_minimo} />
                        </FormControl>}
                        {(tipo_re.includes("Facturas")) &&<FormControl mt={2} ml={1}>
                            <FormLabel>{t("Montante maximo")}</FormLabel>
                            <Input type='number' name='montante_maximo'  required
                              onChange={formik.handleChange}
                             value={formik.values.montante_maximo} />
                        </FormControl>}
                    </Flex>   
                    {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                        <FormLabel>{t("Número de linha do talão")}</FormLabel>
                        <Input type='number' name='numero_de_linhas' min={1} max={4}  required
                          onChange={formik.handleChange}
                         value={formik.values.numero_de_linhas} />
                    </FormControl>}                 
                    {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                        <FormLabel>{t("Texto do talão")}</FormLabel>
                        <Textarea type='date' name='textos_para_talao' maxLength={40} required
                          onChange={formik.handleChange}>{formik.values.textos_para_talao}</Textarea>
                    </FormControl>}
                    {(tipo_re.includes("Facturas")) && <Text fontWeight={900} fontSize={15}>Caracteres restantes: {calcularCaracteresRestantes()}</Text>}
                    
                    
                    <Flex mt={5} direction="row" alignItems="center" justifyContent="space-between">
                      <FormControl ml={3}>
                          <FormLabel>{t("Campo Opcional 1")}</FormLabel>
                          <Tooltip hasArrow label={t('Aqui deves seguir uma padronização de chave e valor que possas ter seu dado adicional. Primeiro digite o nome do campo, sinal de iguel e de seguida o valor para o seu campo. Ilustramos uma exemplo abaixo')} bg='orange.600'>
                            <Input ref={refREFOPT1} type='text' name='opcional_1' placeholder='Ex: campo=valor'
                              onChange={formik.handleChange}
                            value={formik.values.opcional_1} />
                          </Tooltip>
                      </FormControl>
                      <FormControl ml={3}>
                          <FormLabel>{t("Campo Opcional 2")}</FormLabel>
                          <Tooltip hasArrow label={t('Aqui deves seguir uma padronização de chave e valor que possas ter seu dado adicional. Primeiro digite o nome do campo, sinal de iguel e de seguida o valor para o seu campo. Ilustramos uma exemplo abaixo')} bg='orange.600'>
                            <Input ref={refREFOPT2} type='text' name='opcional_2' placeholder='Ex: campo=valor'
                              onChange={formik.handleChange}
                            value={formik.values.opcional_2} />
                          </Tooltip>
                      </FormControl>
                      <FormControl ml={3}>
                          <FormLabel>{t("Campo Opcional 3")}</FormLabel>
                          <Tooltip hasArrow label={t('Aqui deves seguir uma padronização de chave e valor que possas ter seu dado adicional. Primeiro digite o nome do campo, sinal de iguel e de seguida o valor para o seu campo. Ilustramos uma exemplo abaixo')} bg='orange.600'>
                            <Input ref={refREFOPT3} type='text' name='opcional_3' placeholder='Ex: campo=valor'
                              onChange={formik.handleChange}
                            value={formik.values.opcional_3} />
                          </Tooltip>
                      </FormControl>
                    </Flex>
                    <Button leftIcon={<MdPeople/>} bg="blue.400" _hover={{backgroundColor:"blue.600"}} color="white"  type='submit' mt={5}>{t("Criar")}</Button>
                 
                </Box> 
              </form>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }