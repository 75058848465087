import React, {useState, useEffect} from 'react'
import { Button, FormControl, FormLabel, Input, ModalFooter, Modal, Text, Divider, Box, Flex, Heading,
    ModalOverlay,
    ModalContent,
    Tooltip,
    ModalHeader,
    ModalBody,useToast,
    ModalCloseButton,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    TableCaption,
    CircularProgress} from '@chakra-ui/react'
import {MdGridView, MdPassword, MdRefresh, MdRequestPage, MdRequestQuote, MdRestore} from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'

function ModalMudarSenha ({texto, actualizar}) {
    const [open, setOpen] = useState(false)
    const [load, setLoad] = useState(false)
    const {t, i18n} = useTranslation()
    const toast = useToast()    
    const [pagina, setPagina] = useState('1')
    const [paginas, setPaginas] = useState('1')
    const [quantidade, setQuantidade] = useState('10')
    const [referencias, setReferencias] = useState([]);

    const onOpen = () => {
            setOpen(!open)
    }
    
    const fetchRetry = async () => {
      
      setLoad(true)
      const referencia = await AXIOS.get(
        `/logs-requisicao/${texto.id_logreq}/${texto.entidade_aceite}/falhas/reenvios?limite=${quantidade}&pagina=${pagina}`
      );

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }

      setLoad(false)
    }
  
    // const reenviarRequest = async () => {

    //   toast({
    //     title: 'Reenviar requisição',
    //     description: "Á processar",
    //     status: 'loading',
    //     duration: 2500,
    //     isClosable: true,
    //   })

    //   try {

    //     const dadosT = {
    //       id_logreq: texto.id_logreq,
    //       id_evento: Number(texto.evento),
    //       entidade_aceite: texto.entidade_aceite,
    //       dados: JSON.parse(texto.requesicao)
    //     }
          
    //         const responseR = await AXIOS.post('/logs-requisicao/reenvio', dadosT);

    //         if(responseR.data.status == "sucesso"){
    //             setTimeout(()=>{
    //               toast({
    //                 title: 'Reenviar requisição',
    //                 description: responseR.data.mensagem, 
    //                 status: 'success',
    //                 duration: 2500,
    //                 isClosable: true,
    //               })
      
    //             },2800)

    //             actualizar()
    //             onOpen()
    //         }else {
    //           setTimeout(()=>{
    //             toast({
    //               title: 'Reenviar requisição',
    //               description: responseR.data.mensagem, 
    //               status: 'error',
    //               duration: 2500,
    //               isClosable: true,
    //             })
    //         },2800)
    //         }
            
    //     }
    //     catch (error) {
    //       console.log("STATUS",error);
    //       setTimeout(()=>{
    //         toast({
    //           title: 'Reenviar requisição',
    //           description: error.message,
    //           status: 'error',
    //           duration: 2500,
    //           isClosable: true,
    //         })
    //     },2800)
    //     }
    // }

    useEffect(()=>{
      
    },[])
      
    return (
      <>
        <Button leftIcon={<MdRestore/>}  onClick={onOpen} bg='orange.400' color="white" variant='solid'>Analisar</Button>
  
        <Modal isOpen={open} onClose={onOpen} size="6xl" isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
         <ModalContent>
          <ModalHeader>Resposta da requisição | <span style={{fontWeight:900}}>{texto.statusCode}</span></ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Divider />
            <Heading mt={5}>Tentativas executadas</Heading>
            <Box mt={5}>
              <TableContainer mt={3} bg="white" >
                <Table variant="striped" colorScheme="gray">
                  <TableCaption>
                    {!load ? <Button onClick={fetchRetry}><MdRefresh size={20} /></Button>
                    : <CircularProgress isIndeterminate color='blue.300' />}
                  </TableCaption>
                  <Thead p={10}>
                    <Tr bg="gray.600">
                      <Th py={3} color="gray.300">
                        {t("Resultado")}
                      </Th>
                      <Th py={3} color="gray.300">
                        {t("Status code")}
                      </Th>
                      <Th py={3} color="gray.300">
                        {t("Status texto")}
                      </Th>
                      <Th py={3} color="gray.300">
                        {t("HTTP Body")}
                      </Th>
                      <Th py={3} color="gray.300">
                        {t("Executado em")}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {referencias.map((el) => (
                      <Tr key={el.id_logs_tentativa} color="blackAlpha.700">
                        <Td fontWeight={700} color={el.status_tentativa != "SUCESSO" ? "red.500" : el.status_tentativa == "ERRO" ? "green.500" : "black.500" }>{el.status_tentativa}</Td>
                        <Td>{el.status_http}</Td>
                        <Td>{el.status_text_http}</Td>
                        <Td>{el.response_http_body}s </Td>
                        <Td>{new Date(el.tempo_tentado).toLocaleString()}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
            <Box mt={10} bg="gray.100">
              <Flex justifyContent="space-between">
                  <Box p={5}>
                      <Text>ID LOG: <span style={{fontWeight:700}}>{texto.id_logreq}</span></Text>
                      <Text>URL: <span style={{fontWeight:700}}>{texto.url}</span></Text>
                      <Text>Estado da requisição: <span style={{fontWeight:700,color: texto.enviado != "SIM" ? "red.500" : texto.enviado == "Cancelado" ? "red.500" : "black.500" }}>{texto.enviado}</span></Text>
                      <Text>Escala em: <span style={{fontWeight:700}}>{new Date(+texto.timestamp_log).toLocaleString()}</span></Text>
                      <Text>Tentativas Feitas: <span style={{fontWeight:700}}>{texto.tentativas}</span></Text>
                  </Box>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button ml={3} onClick={onOpen}>Sair</Button>
          </ModalFooter>
        </ModalContent>
        </Modal>
      </>
    )
}

export default ModalMudarSenha
