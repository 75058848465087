import {
  Flex,
  Box,
  Text,
  Center,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Td,
  Tbody,
  Tr,
  Th,
  Thead,
  TableCaption,
  TableContainer,
  useToast,
  Button,
  Tooltip,
  Heading
} from "@chakra-ui/react";
import AXIOS from '../Config/axiosConfig'
import { useEffect, useState } from "react";
import {
  SideBarMenu,
  NavBar,
  ModalRemoveEndpoint,
  Loader,
  BotaoTop,
  ModalVerRespostaEndpintRetry,
  ModalVerRespostaEndpint
} from "../Components/index";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Pagination from "react-paginate";
import CONFIG from "../Config";
import {
  MdArrowBack,
  MdArrowForward,
  MdClose,
  MdDone,
  MdDoneOutline,
  MdEdit,
  MdError,
  MdLogoDev,
  MdUpgrade,
  MdWebhook
} from "react-icons/md";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { useTranslation } from "react-i18next";

function Webhooks() {
  const [referencias, setReferencias] = useState([]);
  const [referenciasFilter, setReferenciasFilter] = useState([]);
  
  const {t, i18n} = useTranslation()

  const navigate = useNavigate();
  const loginValue = document.cookie
    .split(";")
    .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
    ? document.cookie
        .split(";")
        .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
        .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
    : "{}";
  const [user, setUser] = useState("1");
  const [pagina, setPagina] = useState('1')
  const [paginas, setPaginas] = useState('1')
  const [quantidade, setQuantidade] = useState('100')
  const toast = useToast();
  const [loader, setLoader] = useState(false);

  async function axiosReferencias() {
    try {
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;
      
      const referencia = await AXIOS.get( 
        `/logs-requisicao/entidade/${entidade}/falhas?limite=${quantidade}&pagina=${pagina}`
      );

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }
      
      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  async function actualizarEndpints() {
    try {
      setLoader(false);
      const entidade = JSON.parse(
        document.cookie
          .split(";")
          .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
          ? document.cookie
              .split(";")
              .find((i) => i.includes(CONFIG.COOKIE_SESSION.CLIENT))
              .split(CONFIG.COOKIE_SESSION.CLIENT)[1]
          : "{}"
      ).mensagem.numero_entidade;
      
      const referencia = await AXIOS.get(
        `/logs-requisicao/entidade/${entidade}/falhas?limite=${quantidade}&pagina=${pagina}`
      );

      if (referencia.data.status === "erro") setReferencias([]);
      else {
        setReferencias(referencia.data.mensagem);
        setReferenciasFilter(referencia.data.mensagem);
        setPagina(referencia.data.registros.pagina_actual) 
        setPaginas(referencia.data.registros.paginas) 
        setQuantidade(referencia.data.registros.limite) 
      }

      setLoader(true);
    } catch (error) {
      navigate("/sem-comunicacao");
    }
  }

  useEffect(() => {
    axiosReferencias();
    const value = JSON.parse(loginValue);
    if (value?.mensagem?.numero_entidade) {
      setUser(value.mensagem);
    } else {
      navigate("/login");
    }
  }, []);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = 20;
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = referenciasFilter.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(referenciasFilter.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % referenciasFilter.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };


  let permissao = user?.pagina_webwook == "true";

  if(user?.pagina_webwook) permissao = user?.pagina_webwook == "true"
  else permissao = user.numero_entidade

  return (
    user?.habilitar_area_tecnica == "true"  ? <>
      <Flex color="white" bg="white" style={{minHeight:'100vh'}} direction="row">
        <SideBarMenu />
        <BotaoTop/>
        <Box flex={1}>
          <NavBar  titulo={t("Falhas de requisições")} imagem={<MdWebhook size={300}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"} />
          <Flex
            direction="column"
            justifyContent="space-between"
            style={{minHeight:'90vh'}}
            mt={5}
          >
             {permissao ? <Box px={10} color="black" mt={10}>
              {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold">
                Webhooks
              </Text> */}
              <Button
                    bg="blue.400"
                    _hover={{ backgroundColor: "blue.600" }}
                    
                    onClick={actualizarEndpints}
                    leftIcon={<MdUpgrade />}
                    color="white"
                  >
                    {t("Actualizar lista")}
              </Button>
              <Button ml={1} as={Link} to="/webhooks" leftIcon={<MdDone/>} colorScheme="orange">Endpoints</Button>
              <Button ml={1} as={Link} to="/webhooks-logs" leftIcon={<MdLogoDev/>} colorScheme="green">Logs</Button>
              {loader ? (
                <Box isLazy bg="white" p={5} mt={5}>
                  <TableContainer mt={3} bg="white" boxShadow="md">
                    <Table variant="striped" colorScheme="gray">
                      <TableCaption>
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<MdArrowForward />}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          breakClassName="list-group-item"
                          previousLabel={<MdArrowBack />}
                          renderOnZeroPageCount={null}
                        />
                      </TableCaption>
                        <Thead p={10}>
                          <Tr bg="gray.600">
                            {/* <Th py={3} color="gray.300">
                              ID Retry
                            </Th> */}
                            <Th py={3} color="gray.300">
                              ID Requisição
                            </Th>
                            <Th py={3} color="gray.300">
                              {t("Resultado")}
                            </Th>
                            <Th py={3} color="gray.300">
                              {t("Status")}
                            </Th>
                            <Th py={3} color="gray.300">
                              {t("Proxima tentativa")}
                            </Th>
                            <Th py={3} color="gray.300">
                              {t("Proximo retry")}
                            </Th>
                            <Th py={3} color="gray.300">
                              {t("Tempo percorrido")}
                            </Th>
                            <Th py={3} color="gray.300">
                              {t("Iniciado em")}
                            </Th>
                            <Th py={3} color="gray.300">
                              {t("Repitidas em")}
                            </Th>
                            <Th py={3} color="gray.300">
                              {t("Ver os resultados")}
                            </Th>
                          </Tr>
                        </Thead>
                      <Tbody>
                        {currentItems.map((el) => (
                          <Tr key={el.id_automatico_requisicoes} color="blackAlpha.700">
                            {/* <Td>{el.id_automatico_requisicoes}</Td> */}
                            <Td>{el.id_logreq}</Td>
                            <Td fontWeight={700} color={el.enviado != "SIM" ? "red.500" : el.enviado == "Cancelado" ? "red.500" : "black.500" }>{el.enviado}</Td>
                            <Td>{el.statusCode}</Td>
                            <Td>{new Date(+el.timestamp_log).toLocaleString()}</Td>
                            <Td>{el.proximoRetryEmSegundo}s </Td>
                            <Td>{el.tempoPercorrido}s </Td>
                            <Td>
                              {new Date(el.data_evento).toLocaleDateString()}{" "}
                              {new Date(el.data_evento).toLocaleTimeString()}
                            </Td>
                            <Td>{el.tentativas}x</Td>
                            <Td>
                              <ModalVerRespostaEndpint actualizar={axiosReferencias} texto={el}/>
                              <b style={{marginLeft:10}}></b>
                              <ModalVerRespostaEndpintRetry actualizar={axiosReferencias} texto={el}/>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              ) : (
                <Center>
                  {/* <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  /> */}
                  <Loader />
                </Center>
              )}
            </Box>
            :<Center width="100%">
              <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                  <MdError size={200} color="red"/>
                  <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
                  <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
              </Flex>
            </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
              <Text>
                <b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} *{" "}
                {CONFIG.COMPANY.EMAILS[0]}
              </Text>
              <Text>
                {t("Todos direitos reservados")} &copy; {new Date().getFullYear()}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
    :
    navigate("/404")
  );
}

export default Webhooks;
