import React, {useState} from 'react'
import { Button, Flex,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,Card, CardHeader, CardBody, Box, Heading, Text, Stack, StackDivider, 
    useToast} from '@chakra-ui/react'
import { MdClose, MdSearch } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import AXIOS from '../Config/axiosConfig'

function ModalViewReference({registo}) {
    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const toast = useToast()
    const TIPO_TERMINAL = {
      "A": "ATM",
      "M": "Multicaixa Express",
      "L": "Internet Banking",
      "1": "Internet Banking",
    }

    const onOpen = () => {
            setOpen(!open)
    }

    const reenviarRequest = async () => {

      toast({
        title: 'Reenviar requisição',
        description: "Á processar",
        status: 'loading',
        duration: 2500,
        isClosable: true,
      })

      try {

        const dadosT = {
          id_evento: Number(registo.evento),
          entidade_aceite: registo.numero_entidade,
          dados: registo
        }
          
            const responseR = await AXIOS.post('/logs-requisicao/reenvio-forcado', dadosT);

            if(responseR.data.status == "sucesso"){
                setTimeout(()=>{
                  toast({
                    title: 'Reenviar requisição',
                    description: responseR.data.mensagem, 
                    status: 'success',
                    duration: 2500,
                    isClosable: true,
                  })
      
                },2800)

               
                onOpen()
            }else {
              setTimeout(()=>{
                toast({
                  title: 'Reenviar requisição',
                  description: responseR.data.mensagem, 
                  status: 'error',
                  duration: 2500,
                  isClosable: true,
                })
            },2800)
            }
            
        }
        catch (error) {
          console.log("STATUS",error);
          setTimeout(()=>{
            toast({
              title: 'Reenviar requisição',
              description: error.message,
              status: 'error',
              duration: 2500,
              isClosable: true,
            })
        },2800)
        }
    }
    
    return (
      <>
        <Button onClick={onOpen} size="sm" leftIcon={<MdSearch />} bg='blue.400' color="white" variant='solid'></Button>
  
        <Modal isOpen={open} onClose={onOpen} size={registo.id_transaccao ? "6xl" : "2xl"} isCentered>
          <ModalOverlay bg='blackAlpha.300'
      backdropFilter='blur(10px)' />
          <ModalContent>
            <ModalHeader>{t("Mais informações do pagamento")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" justifyContent="space-between">
            <Card flex={1}>
                  <CardHeader>
                    <Heading size='md'>REF: {registo.referencia_do_servico}</Heading>
                  </CardHeader>
                
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Tipo de registo")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {t(registo.registo_produto == "Pagamento/Carregamento" ? registo.tipo_referencia_pagamento == "P" ? "Pagamentos" : "Carregamentos" : registo.registo_produto)}
                        </Text>
                      </Box>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Tarifa aplicada á operação")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                          AKZ {Intl.NumberFormat('PT-br').format(Number(registo.tarifa_aplicada_a_operacao)) ? Intl.NumberFormat('PT-br').format(Number(registo.tarifa_aplicada_a_operacao)) : '0.00'}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante da operação")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                          AKZ {Intl.NumberFormat('PT-br').format(registo.montante_da_operacao)}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Produto")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.produto}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Moeda")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.codigo_de_Moeda }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Transacão do cliente")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.data_hora_transacao_cliente ? new Date(registo.data_hora_transacao_cliente).toLocaleDateString() : <Text color="red.400" fontWeight={400}>Aguarde a concliação bancária</Text>}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Data de limite de pagamento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {new Date(registo.data_limite_pagamento).toLocaleDateString()}
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            {t("Aplicação PDD")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.aplicaccao_PDD}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            {t("Tipo de terminal")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {TIPO_TERMINAL[registo.tipo_de_Terminal]}
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            {t("Identificacao Log EGR")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.Identificacao_Log_EGR}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            {t("Número Log EGR")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.numero_Log_EGR }
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Data do movimento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {new Date(registo.data_movimento).toLocaleDateString()}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Hora do movimento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {new Date('2000-01-01 '+registo.hora_do_movimento).toLocaleTimeString()}
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            {t("Número periodo contabilistico")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.numero_Periodo_Contabilistico}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                            {t("Identificacao transacao local")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.identificacao_Transacao_Local }
                            </Text>
                          </Box>
                      </Flex>
                    </Stack>
                  </CardBody>
            </Card>
            {registo.id_transaccao && <Card flex={1}>
                  <CardHeader>
                    <Heading color="green.400" size='lg'>Transação</Heading>
                  </CardHeader>
                
                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Valor total da transação")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          AKZ {Intl.NumberFormat('PT-br').format(registo.valor_total)}
                        </Text>
                      </Box>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Minimo parametrizado")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                          AKZ {Intl.NumberFormat('PT-br').format(registo.minimo_actual)}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Maximo parametrizado")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                          AKZ {Intl.NumberFormat('PT-br').format(registo.maximo_actual)}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Percentual à aplicar")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.percentagem_actual}%
                            </Text>
                          </Box>
                      </Flex>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Button onClick={reenviarRequest} colorScheme='orange'>Enviar request no webhook</Button>
                          </Box>
                      </Flex>
                    </Stack>
                  </CardBody>
            </Card>}
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onOpen}>
                <MdClose/>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default ModalViewReference
