import { useEffect, useRef, useState } from 'react'
import { MdClose, MdDelete, MdError, MdOutlineFileDownload, MdPeople, MdUpdate } from "react-icons/md"
import {  BotaoTop, DrawerProdutos, DrawerTipoProdutos, ModalMudarSenha, NavBar, NavBarAdm, SideBarMenu, SideBarMenuAdm } from '../../Components/index'
import { useNavigate, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, CardHeader, Stack, StackDivider, Flex, Box, Text, Button, Input, FormControl, FormLabel, Textarea, Select, Center, Spinner, useToast,  Radio, RadioGroup, AlertIcon, Alert, AlertTitle, AlertDescription} from '@chakra-ui/react'
import {useFormik} from 'formik'
import AXIOS from '../../Config/axiosConfig'
import CONFIG from '../../Config'
import Entidades from './Entidades';
import { FileUploader } from 'react-drag-drop-files'

const fileTypes = ["PDF"];

function EditarEntidades() {

    const [registo, setReferencias] = useState({})    
    const [produtosTipo, setProdutosTipo] = useState([])    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.ADM)).split(CONFIG.COOKIE_SESSION.ADM)[1] : "{}"
    const [user, setUser] = useState({})
    const [gpo, setGPO] = useState("Referências")
    const {id} = useParams();
    const [loader, setLoader] = useState(false)
    const REFForm = useRef(null)
    
    const toast = useToast()
    
    async function axiosReferencias(){
      const referencia = await AXIOS.get(`/clientes/${id}`);
      const produto_tipo = await AXIOS.get(`/registro-produtos-cliente/cliente/${id}`)
      
      
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        setReferencias(referencia.data.mensagem[0])
      }
      
      if(produto_tipo.data.status === "erro")
        setProdutosTipo([])
      else{
        setProdutosTipo(produto_tipo.data.mensagem[0]?.registo)
      }
      
      setLoader(true)
      
      if(referencia.data.mensagem.length){
            console.log(REFForm.current)
      }else{
        navigate('/adm')
      }
          
  }    
  
  
  const formik = useFormik({
    initialValues:{
      nome_empresa:'',
      nif:'',
      contacto:'',
      email: '',
      senha: '',
      numero_entidade: '',
      num_padrao_referencias: '',
      responsavel: '',
      data_de_inicio_de_contrato: '',
      data_de_fim_de_contrato: '',
      montante_maximo_pagamento: '19999999',
      montante_minimo_pagamento: '10',
      versao_mensagem_PRT: '04', 
      parametrizado_como:'Novo',
      validacao_referencias:'Realtime',
      ultimo_ficheiro_enviado_a_emis:'00000000000',
      gpo_numero_comerciante: null,
      gpo_numero_cartao: null,
      gpo_numero_banco: null,
      gpo_numero_POS: null,
      gpo_numero_establecimento: null
    },
    onSubmit: async (values)=> {

      // if(!/(\.)/g.test(values.montante_minimo_pagamento)){

      //   toast({
      //     title: 'Actualização de entidade',
      //     description: "Seu montante minimo de ser decimal",
      //     status: 'error',
      //     duration: 5000,
      //     isClosable: true,
      //   })

      //   return
      // }

      // if(!/(\.)/g.test(values.montante_maximo_pagamento)){

      //   toast({
      //     title: 'Actualização de entidade',
      //     description: "Seu montante maximo de ser decimal",
      //     status: 'error',
      //     duration: 5000,
      //     isClosable: true,
      //   })

      //   return
      // }

      const res =  await AXIOS.patch(`/clientes/${id}`,values)
      
      toast({
        title: 'Actualização de entidade',
        description: "A fazer o pedido",
        status: 'loading',
        duration: 1000,
        isClosable: true,
      })

      if(res.data.status === "sucesso"){
        axiosReferencias()
        
        
        setTimeout(()=>{
          toast({
            title: 'Actualização de entidade',
            description: res.data.mensagem,
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        },2000)
      }else{
        toast({
          title: 'Actualização de entidade',
          description: res.data.mensagem,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      
      
    },
  })
  

  const uploadFile = async (file) => {
    
    try {
        const form = new FormData();
        const filename = registo.email + Date.now() + "." +  file.type.split('/')[1]
        form.append('arquivo_contrato', file, filename)
        form.append('id_clientes', registo.id_clientes)
        
        const res = await AXIOS.post(`/clientes/mudar/arquivo-contrato`,form)
        if(res.data.status === "sucesso"){
      
          toast({
            title: 'Upload do arquivo de contrato',
            description: res.data.mensagem,
            status: 'success',
            duration: 2000,
            isClosable: true,
          })
          
          
      }else{
        toast({
          title: 'Upload do arquivo de contrato',
          description: res.data.mensagem,
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      
      }

      console.log(form)
    } catch (error) {
      toast({
        title: 'Alerta',
        description:"Não conseguimos ter comunicação! Verifique sua internet",
        status: 'error',
      })
    }
}
  
  
  
    useEffect(()=>{
       
              axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.id_usuarios){
       setUser(value.mensagem)
      }else{
            navigate('/adm/login')
          }
          
          // if(id) navigate('/referencias')
    },[])
    
  
  const mudarSenha = async (senha, repetirsenha)=>{
  
    if(senha == repetirsenha){
      const res =  await AXIOS.patch(`/clientes/${registo.id_clientes}`,{senha})
      
      toast({
        title: 'Actualização de senha',
        description: "Á processar",
        status: 'loading',
        duration: 1000,
        isClosable: true,
      })

      if(res.data.status === "sucesso"){
        
        setTimeout(()=>{
          toast({
            title: 'Actualização de senha',
            description: res.data.mensagem,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          axiosReferencias()
        },2000)
      }else{
        setTimeout(()=>{
          toast({
            title: 'Actualização de senha',
            description: res.data.mensagem,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        },2000)
      }
    }else{
      toast({
        title: 'Actualização de senha',
        description: "Senhas devem ser iguais",
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }
    
  
  formik.initialValues.nome_empresa = registo?.nome_empresa
  formik.initialValues.nif = registo?.nif
  formik.initialValues.contacto = registo?.contacto
  formik.initialValues.email = registo?.email
  formik.initialValues.numero_entidade = registo?.numero_entidade
  formik.initialValues.num_padrao_referencias = registo?.num_padrao_referencias
  formik.initialValues.responsavel = registo?.responsavel
  formik.initialValues.data_de_inicio_de_contrato = registo?.data_de_inicio_de_contrato
  formik.initialValues.data_de_fim_de_contrato = registo?.data_de_fim_de_contrato
  formik.initialValues.montante_maximo_pagamento = registo?.montante_maximo_pagamento
  formik.initialValues.montante_minimo_pagamento = registo?.montante_minimo_pagamento
  formik.initialValues.versao_mensagem_PRT = registo?.versao_mensagem_PRT
  formik.initialValues.caraterizacao_cliente = registo?.caraterizacao_cliente
  formik.initialValues.entidade_master = registo?.entidade_master
  formik.initialValues.parametrizado_como = registo?.parametrizado_como
  formik.initialValues.validacao_referencias = registo?.validacao_referencias
  formik.initialValues.preenchimento_refs_zeros_a_esquerda = registo?.preenchimento_refs_zeros_a_esquerda
  formik.initialValues.ultimo_ficheiro_enviado_a_emis = registo?.ultimo_ficheiro_enviado_a_emis
  formik.initialValues.gpo_numero_comerciante = registo?.gpo_numero_comerciante
  formik.initialValues.gpo_numero_cartao = registo?.gpo_numero_cartao
  formik.initialValues.gpo_numero_banco = registo?.gpo_numero_banco
  formik.initialValues.gpo_numero_POS = registo?.gpo_numero_POS
  formik.initialValues.gpo_numero_establecimento = registo?.gpo_numero_establecimento
  

  let permissao = user?.pagina_entidades == "true";

  if(user?.pagina_entidades) permissao = user?.pagina_entidades == "true"
  else permissao = user.todas_areas ? true : false;
  
  return (
    <>
    <Flex color='white' bg="gray.300" direction="row">
    <SideBarMenuAdm/>
    <BotaoTop/>
    <Box flex={1}>
      <NavBarAdm/>{

          permissao

          ? 
          
          <Box px={10} color="black" mt={10}>
            <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>Modificar a entidade</Text>
            {loader ?
            <Flex color="black" my={5} direction="column">
              
                <Flex justifyContent="space-between">
                  {registo?.id_clientes && <Card flex={1}>
                        <CardHeader>
                          <Heading size='md'>Codigo da entidade: {registo.numero_entidade || <span style={{color:"red"}}>Sem serviço de pagamento por referência</span>}</Heading>
                          <Heading mt={3} size='sm' color="gray.500">Codigo de comerciante: {registo.gpo_numero_comerciante || <span style={{color:"red"}}>Sem serviço de gateway pagamento online (GPO)</span> }</Heading>
                        </CardHeader>
                      
                        <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Inicio de contrato
                              </Heading>
                              <Text pt='2' fontSize='sm' color="green.500">
                                {registo.data_de_inicio_de_contrato ? new Date(registo.data_de_inicio_de_contrato).toLocaleDateString() : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Final de contrato
                              </Heading>
                              <Text pt='2' fontSize='sm' color="red.500">
                                {registo.data_de_fim_de_contrato ? new Date(registo.data_de_fim_de_contrato).toLocaleDateString() : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                        </Flex>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Criado em
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.criando_em ? new Date(registo.criando_em).toLocaleDateString() + " " + new Date(registo.criando_em).toLocaleTimeString() : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Actualizado em
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.actualizado_em ? new Date(registo.actualizado_em).toLocaleDateString() + " " + new Date(registo.criando_em).toLocaleTimeString() : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                        </Flex>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Responsavel
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.responsavel ? registo.responsavel : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Activação
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.bloqueio == 1 ? "activo" : "Inactivo" }
                              </Text>
                            </Box>
                        </Flex>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Terminal
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.contacto ? registo.contacto : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                               Email
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.email ? registo.email : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                        </Flex>
                        {gpo == "Referências" && <>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Caraecteres para entidade
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.num_padrao_referencias ? registo.num_padrao_referencias : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                               Codigo de entidade
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.numero_entidade ? registo.numero_entidade : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                        </Flex>
                        <Flex direction="row" alignItems="center" justifyContent="space-between" mt={5}>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Montante minimo
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.montante_minimo_pagamento ? Intl.NumberFormat("PT-br").format(registo.montante_minimo_pagamento) : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                               Montante maximo
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.montante_maximo_pagamento ? Intl.NumberFormat("PT-br").format(registo.montante_maximo_pagamento) : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                        </Flex>
                        <Flex direction="row"  mt={5} alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Responsavel do serviço
                              </Heading>
                              <Text pt='2' fontSize='sm' color="orange.500">
                                {registo.responsavel ? registo.responsavel : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Entidade Master
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.entidade_master ? registo.entidade_master : "por parametrizar"}
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Tipo de cliente
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.caraterizacao_cliente}
                              </Text>
                            </Box>
                        </Flex>
                        <Flex direction="row"  mt={5} alignItems="center" justifyContent="space-between">
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Entidade parametrizada como
                              </Heading>
                              <Text pt='2' fontSize='sm' color="red.500">
                                {registo.parametrizado_como ? registo.parametrizado_como : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Validação das referências
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.validacao_referencias ? registo.validacao_referencias : "por parametrizar"}
                              </Text>
                            </Box>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                Preenchimento de zeros á esquerda
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.preenchimento_refs_zeros_a_esquerda ? registo.preenchimento_refs_zeros_a_esquerda : "por parametrizar"}
                              </Text>
                            </Box>
                        </Flex>
                        </>}

                       { gpo == "GPO" &&  <>
                        <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                Número de Comerciante
                                </Heading>
                                <Text pt='2' fontSize='sm' color="red.500">
                                  {registo.gpo_numero_comerciante ? registo.gpo_numero_comerciante : <MdClose size={20} color='red'/> }
                                </Text>
                              </Box>
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                Número de Establecimento
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.gpo_numero_establecimento ? registo.gpo_numero_establecimento : "por parametrizar"}
                                </Text>
                              </Box>
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                Número de Terminais (POS)
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.gpo_numero_POS ? registo.gpo_numero_POS : "por parametrizar"}
                                </Text>
                              </Box>
                          </Flex>
                          <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                Número de Cartões
                                </Heading>
                                <Text pt='2' fontSize='sm' color="red.500">
                                  {registo.gpo_numero_cartao ? registo.gpo_numero_cartao : <MdClose size={20} color='red'/> }
                                </Text>
                              </Box>
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                Número de Banco
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.gpo_numero_banco ? registo.gpo_numero_banco : "por parametrizar"}
                                </Text>
                              </Box>
                          </Flex> 
                        </>}
                        <ModalMudarSenha mudarSenha={mudarSenha} />
                        <Box mt={6} fontSize={16} fontWeight={300}>
                            <Heading mb={3} size="md">Arquivo de contrato</Heading>
                            <FileUploader handleChange={uploadFile} name="file" types={fileTypes} />                        
                            {registo.arquivo_do_contrato && <Text mt={5} p="2" bg="gray.100" shadow="md"><a target='_blank' download={`${CONFIG.HOST_API}/contratos/`+registo.arquivo_do_contrato} href={`${CONFIG.HOST_API}/contratos/`+registo.arquivo_do_contrato}><MdOutlineFileDownload size={30} /> Baixar arquivo do contrato</a></Text>}
                        </Box>
                        </Stack>
                        </CardBody>
                        </Card>}
                        {registo?.id_clientes && <Box flex={1} px={10}>

                        <form onSubmit={formik.handleSubmit} ref={REFForm}>
                          <Box bg='white' p={10} rounded={5}  shadow="sm">
                          <RadioGroup mb={10} onChange={setGPO} value={gpo}>
                            <Stack direction='row' justifyContent="center">
                              <Radio value="Referências">Pagamento por referência</Radio>
                              <Radio value="GPO">Gateway de pagamentos online (GPO)</Radio>
                            </Stack>
                          </RadioGroup>
                          <FormControl>
                                <FormLabel>Empresa</FormLabel>
                                <Flex direction="row" alignItems="center">
                                  <Input type='text' name='nome_empresa' onChange={formik.handleChange} value={formik.values.nome_empresa} />
                                </Flex>
                            </FormControl>
                            <FormControl mt={2}>
                                <FormLabel>NIF</FormLabel>
                                <Flex direction="row" alignIterems="center">
                                  <Input type='text' name='nif'  required  onChange={formik.handleChange} value={formik.values.nif} />
                                </Flex>
                            </FormControl>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <FormControl mt={2}>
                                  <FormLabel>Contacto</FormLabel>
                                  <Input type='tel' name='contacto' onChange={formik.handleChange} required value={formik.values.contacto} />
                              </FormControl>
                              <FormControl mt={2} ml={2}>
                                  <FormLabel>Email</FormLabel>
                                  <Input type='email' name='email'  required onChange={formik.handleChange} value={formik.values.email} />
                              </FormControl>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <FormControl mt={2}>
                                  <FormLabel>Inicio de contrato</FormLabel>
                                  <Input type='date' name='data_de_inicio_de_contrato' onChange={formik.handleChange} required defaultValue={registo?.data_de_inicio_de_contrato} value={formik.values.data_de_inicio_de_contrato}/>
                              </FormControl>
                              <FormControl mt={2} ml={2}>
                                  <FormLabel>Final de contrato</FormLabel>
                                  <Input type='date' name='data_de_fim_de_contrato' onChange={formik.handleChange} required defaultValue={registo?.data_de_fim_de_contrato} value={formik.values.data_de_fim_de_contrato} />
                              </FormControl>
                            </Flex>
                            <FormControl mt={2}>
                                <FormLabel>Responsável</FormLabel>
                                <Input type='text' name='responsavel' required onChange={formik.handleChange}  value={formik.values.responsavel} />
                            </FormControl>
                            {gpo == "Referências" && <><Flex direction="row" alignItems="center" justifyContent="space-between">
                                <FormControl mt={2}>
                                    <FormLabel>Codigo de entidade</FormLabel>
                                    <Input type='text' name='numero_entidade' onChange={formik.handleChange} value={formik.values.numero_entidade} />
                                </FormControl>
                                <FormControl mt={2} ml={2}>
                                  <FormLabel>Tipo de cliente</FormLabel>
                                  <Select required name='caraterizacao_cliente' onChange={formik.handleChange} value={formik.values.caraterizacao_cliente}>
                                    <option value="Comun">Comun</option>
                                    <option value="Especifico">Especifico</option>
                                  </Select>
                              </FormControl>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <FormControl mt={2}>
                                <FormLabel>N. C. para referências</FormLabel>
                                <Input type='number' min={9} max={15} name='num_padrao_referencias' required  onChange={formik.handleChange} value={formik.values.num_padrao_referencias} />
                            </FormControl>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                            <FormControl mt={2}>
                                <FormLabel>Montante Minimo</FormLabel>
                                <Input type='number' min={100} max={19999999} name='montante_minimo_pagamento' required onChange={formik.handleChange} value={formik.values.montante_minimo_pagamento} />
                            </FormControl>
                            <FormControl mt={2} ml={2}>
                                <FormLabel>Montante Maximo</FormLabel>
                                <Input type='number' min={10} max={19999999} name='montante_maximo_pagamento' required onChange={formik.handleChange} value={formik.values.montante_maximo_pagamento} />
                            </FormControl>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <FormControl mt={2}>
                                  <FormLabel>Entidade Master</FormLabel>
                                  <Input type='text' min={100} max={10000000} name='entidade_master' onChange={formik.handleChange} value={formik.values.entidade_master} />
                              </FormControl>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <FormControl mt={2}>
                                  <FormLabel>Versão da mensagem</FormLabel>
                                  <Select required name='versao_mensagem_PRT' onChange={formik.handleChange} value={formik.values.versao_mensagem_PRT}>
                                    <option value="03">Versão 3</option>
                                    <option value="04">Versão 4</option>
                                    <option value="05">Versão 5</option>
                                  </Select>
                              </FormControl>
                              <FormControl mt={2}  ml={2}>
                                  <FormLabel>Parametrizado como</FormLabel>
                                  <Select required name='parametrizado_como' onChange={formik.handleChange} value={formik.values.parametrizado_como}>
                                    <option value="Novo">Novo</option>
                                    <option value="Migrado">Migrado</option>
                                  </Select>
                              </FormControl>
                              <FormControl mt={2}  ml={2}>
                                  <FormLabel>Preencher zeros á esquerda</FormLabel>
                                  <Select required name='preenchimento_refs_zeros_a_esquerda' onChange={formik.handleChange} value={formik.values.preenchimento_refs_zeros_a_esquerda}>
                                    <option value="NÃO">NÃO</option>
                                    <option value="SIM">SIM</option>
                                  </Select>
                              </FormControl>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <FormControl mt={2}>
                                  <FormLabel>Validação de referencias e pagamentos</FormLabel>
                                  <Select required name='validacao_referencias' onChange={formik.handleChange} value={formik.values.validacao_referencias}>
                                    <option value="Realtime">Realtime</option>
                                    <option value="Ficheiros">Ficheiros</option>
                                  </Select>
                              </FormControl>
                              {((formik.values.validacao_referencias == "Ficheiros") && (formik.values.parametrizado_como == "Migrado")) && <FormControl mt={2} ml={2}>
                                  <FormLabel>Ultimo ficheiro enviado</FormLabel>
                                  <Input type='text' defaultValue="00000000000" name='ultimo_ficheiro_enviado_a_emis' onChange={formik.handleChange} value={formik.values.ultimo_ficheiro_enviado_a_emis} />
                              </FormControl>}
                            </Flex>
                            </>}

                            {gpo == "GPO" && <>
                              <Flex mt={5} direction="row" alignItems="center" justifyContent="space-between">
                                <FormControl mt={2}>
                                    <FormLabel>Número de Comerciante</FormLabel>
                                    <Input type='text' minLength={5} maxLength={10} name='gpo_numero_comerciante' required onChange={formik.handleChange} value={formik.values.gpo_numero_comerciante} />
                                </FormControl>
                                <FormControl mt={2} ml={2}>
                                    <FormLabel>Número de Establecimento</FormLabel>
                                    <Input type='text' minLength={1} maxLength={15} name='gpo_numero_establecimento' required  onChange={formik.handleChange} value={formik.values.gpo_numero_establecimento} />
                                </FormControl>
                                <FormControl mt={2} ml={2}>
                                    <FormLabel>Número de Terminais (POS)</FormLabel>
                                    <Input type='text' minLength={1} maxLength={3} name='gpo_numero_POS' required onChange={formik.handleChange} value={formik.values.gpo_numero_POS} />
                                </FormControl>
                              </Flex>
                              <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <FormControl mt={2}>
                                    <FormLabel>Número de Cartões</FormLabel>
                                    <Input type='text' minLength={1} maxLength={10000000} name='gpo_numero_cartao' required  onChange={formik.handleChange} value={formik.values.gpo_numero_cartao} />
                                </FormControl>
                                <FormControl mt={2} ml={2}>
                                    <FormLabel>Número de Banco</FormLabel>
                                    <Input type='text' minLength={1} maxLength={10000000} name='gpo_numero_banco' onChange={formik.handleChange} value={formik.values.gpo_numero_banco} />
                                </FormControl>
                              </Flex>
                            </>}

                        <Button leftIcon={<MdPeople/>} bg="blue.900" color="white"  type='submit' mt={5}>Actualizar</Button>
                        
                        </Box> 
                      </form>
                        </Box>}
                        {(registo?.id_clientes == false) && <Center>
                            <Heading textAlign="center" fontWeight={300} fontSize={20}>Esta entidae é invalida! Por vafor selecione uma entidade valida</Heading>
                        </Center>}
                </Flex>
            </Flex>
            :
            <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>}
          </Box>
          :
          
          <Box display="flex" flexDirection="column" height="100vh" justifyContent="center" alignItems="center" px={10} color="black" mt={10}>
            <Text fontSize={34} fontWeight={700}>Não têm permissão para essa página</Text>
            <Text fontSize={18}>Contacte ao administrador para possiveis resoluções</Text>
            <MdError color='red' size={200} />
          </Box>
        }
      </Box>
    </Flex>
  </>
  )
}

export default EditarEntidades