import { useEffect, useRef, useState } from 'react'
import { MdClose, MdError, MdOutlineWifiTetheringErrorRounded, MdPeople, MdUpdate } from "react-icons/md"
import {  Loader, NavBar, SideBarMenu } from '../Components/index'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, CardHeader, Stack, StackDivider, Flex, Box, Text, Button, Input, FormControl, FormLabel, Textarea, Select, Center,  useToast} from '@chakra-ui/react'
import {useFormik} from 'formik'
import AXIOS from '../Config/axiosConfig'
import CONFIG from '../Config'
import { FcProcess } from "react-icons/fc";
import { HiStatusOnline, HiStatusOffline } from "react-icons/hi";
import { useTranslation } from 'react-i18next'
import { BsBank } from 'react-icons/bs'


function Referencias() {

    const [registo, setReferencias] = useState({})    
    const navigate =  useNavigate()
    const loginValue = document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}"
    const [user, setUser] = useState({})
    const [referencias, setReferenciasEnti] = useState([])
    const {id} = useParams();
    const refREF = useRef(null)       
    const refREFOPT1 = useRef(null)
    const refREFOPT2 = useRef(null)
    const refREFOPT3 = useRef(null)
    const [produtos, setProdutos] = useState([])
    const refREFMontante = useRef(null)
    const [tipo_re, setTipo_re] = useState(["Pagamento/Carregamento"])    
    const [indicador_id, setIndicador_produto_id] = useState('1')
    const refCodCli = useRef(null)
    const [loader, setLoader] = useState(false)
    const toast = useToast()
    const {t, i18n} = useTranslation()

    async function axiosReferencias(){
      try {
      const entidade = JSON.parse(document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)) ? document.cookie.split(";").find(i => i.includes(CONFIG.COOKIE_SESSION.CLIENT)).split(CONFIG.COOKIE_SESSION.CLIENT)[1] : "{}").mensagem.numero_entidade
      
      const referencia = await AXIOS.get(`/referencias/referencia/${id}/${entidade}`);
     
      if(referencia.data.status === "erro")
          setReferencias([])
      else{
        if(referencia.data.mensagem.length){
        
            if(referencia.data.mensagem[0].entidade_cliente == entidade){
                setReferencias(referencia.data.mensagem[0])
                
            }else{
                setReferencias([])
                }
        }else{
          setReferencias([])
        }
      }
      
      
      const referenciaF = await AXIOS.get(`/registro-produtos-cliente/entidade/${entidade}`)
      if(referenciaF.data.status === "erro")
          setReferenciasEnti([])
      else{
          setReferenciasEnti(referenciaF.data.mensagem[0].registo)
      }
      
      setLoader(true)
      
      buscarProduto(referencia.data.mensagem[0].tipo_de_registro)
      onTipoRegisto(referencia.data.mensagem[0].tipo_de_registro)
    } catch (error) {
      navigate('/sem-comunicacao')
  }  
          
  }    
  
  const onTipoRegisto = (value)=>{
       
    if(value === "1"){
      setTipo_re(["Pagamento/Carregamento"])
    }
    
    if(value === "2"){
      setTipo_re(["Recargas"])
    }
    
    if(value === "3"){
      setTipo_re(["Facturas"])
    }
    
    buscarProduto(value)
}

const buscarProduto =  async (item) =>{
try {
  const referencia = await AXIOS.get(`/produtos-clientes/produto/${item}`)
  if(referencia.data.status === "erro")
      setProdutos([])
    else{
      if(referencia.data.mensagem.length)
        setProdutos(referencia.data.mensagem[0].registo)
      else
        setProdutos([])
    }
  } catch (error) {
    toast({
      title: 'Alerta',
      description: "Não conseguimos ter comunicação! Verifique sua internet",
      status: 'error',
    })
} 
}

  const formik = useFormik({
    initialValues:{
      num_referencia:'',
      tipo_de_registro:'',
      referencia_do_montante:'',
      indicador_de_produtos: '',
      codigo_de_processamento: '80',
      textos_para_talao: '',
      quantidade_de_unidades: '',
      codigo_de_ativacao: '',
      numero_serie_helpDesk: '',
      chave_ativacao: '',
      data_de_validade: '',
      montante_maximo: '',
      montante_minimo: '',
      data_inicio_de_pagamento: '',
      codigo_de_cliente: '',
      numero_de_linhas: '',
      opcional_1: '',
      opcional_2: '',
      opcional_3: '',
    },
    onSubmit: async (values)=> {
      
      try {
      
    if((/[a-zA-Z]{2,}=[\w{2,}\.\-\,\d\s]{1,}/ig.exec(values.opcional_1)) && values.opcional_1 != ""){
          refREFOPT1.current.style = "border:1px solid green";
    }else {
          formik.initialValues.opcional_1 = ""
          values.opcional_1 = "";
    }

    if((/[a-zA-Z]{2,}=[\w{2,}\.\-\,\d\s]{1,}/ig.exec(values.opcional_2)) && values.opcional_2 != ""){
          refREFOPT2.current.style = "border:1px solid green";
    }else {
          formik.initialValues.opcional_2 = ""
          values.opcional_2 = "";
    }

    if((/[a-zA-Z]{2,}=[\w{2,}\.\-\,\d\s]{1,}/ig.exec(values.opcional_3)) && values.opcional_3 != ""){
          refREFOPT3.current.style = "border:1px solid green";
    }else {
          formik.initialValues.opcional_3 = ""
          values.opcional_3 = "";
    }

      const res =  await AXIOS.patch(`/referencias/${registo.id_referencia}`,{...values})
      
      if(res.data.status === "sucesso"){
        toast({
          title: t('Actulização de referência'),
          description:t (res.data.mensagem),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        
      }else{
        toast({
          title:t ('Actulização de referência'),
          description:t (res.data.mensagem),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
      axiosReferencias(); 
      
    } catch (error) {
      toast({
        title:t ('Alerta'),
        description:t ("Não conseguimos ter comunicação! Verifique sua internet"),
        status: 'error',
      })
  } 
      
    },
  })
  
  const validarDigitos = (evt) =>{
      const keys = ["8","46","48","49","50","51","52","53","54","55","56","57"]
      const find = keys.find(key => key == evt.keyCode)
      if(!find) evt.target.value = evt.target.value.replace(evt.target.value.substr(-1),'')
      
      
  }
  
  
  
  
    useEffect(()=>{
              axiosReferencias(); 
      const value = JSON.parse(loginValue)
      if(value?.mensagem?.numero_entidade){
       setUser(value.mensagem)
      }else{
            navigate('/login')
          }
          
          // if(id) navigate('/referencias')
    },[])
    
    
      formik.initialValues.num_referencia = registo.num_referencia || registo.referencia_do_montante
      formik.initialValues.tipo_de_registro = registo.tipo_de_registro
      formik.initialValues.referencia_do_montante = registo.referencia_do_montante
      formik.initialValues.indicador_de_produtos =  registo.indicador_de_produtos
      formik.initialValues.codigo_de_processamento =  registo.codigo_de_processamento
      formik.initialValues.textos_para_talao =  registo.textos_para_talao
      formik.initialValues.quantidade_de_unidades =  registo.quantidade_de_unidades
      formik.initialValues.codigo_de_ativacao =  registo.codigo_de_ativacao
      formik.initialValues.numero_serie_helpDesk =  registo.numero_serie_helpDesk
      formik.initialValues.chave_ativacao =  registo.chave_ativacao
      formik.initialValues.data_de_validade =  registo.data_de_validade
      formik.initialValues.montante_minimo =  registo.montante_minimo
      formik.initialValues.montante_maximo =  registo.montante_maximo
      formik.initialValues.data_inicio_de_pagamento =  registo.data_inicio_de_pagamento
      formik.initialValues.data_limite_pagamento =  registo.data_limite_pagamento
      formik.initialValues.hora_limite_pagamento =  registo.hora_limite_pagamento
      formik.initialValues.codigo_de_cliente =  registo.codigo_de_cliente
      formik.initialValues.numero_de_linhas =  registo.numero_de_linhas
      formik.initialValues.opcional_1 =  registo.opcional_1
      formik.initialValues.opcional_2 =  registo.opcional_2
      formik.initialValues.opcional_3 =  registo.opcional_3

      let permissao = user?.pagina_referencia == "true";

      if(user?.pagina_referencia) permissao = user?.pagina_referencia == "true"
      else permissao = user.numero_entidade
    
  return (
  <>
    <Flex color='white' bg="white" direction="row" style={{minHeight:'100vh'}}>
      <SideBarMenu/>
      <Box flex={1}>
        <NavBar  titulo={t("Modificar sua referência")} imagem={<BsBank size={200}/>} dizeres={"Implementar apps da Web e apps da Web para dispositivos móveis em segundos usando uma rede de fornecimento de conteúdo global segura"}  />
        <Flex direction="column" justifyContent="space-between" style={{minHeight:'90vh'}}>
        {permissao ? <Box px={10} color="black" mt={10}>
              {/* <Text fontSize={50} color="blackAlpha.600" fontWeight="bold" mb={8}>{t("Modificar sua referência")}</Text> */}
              {loader ?
              <Flex color="black" >
                  {registo?.codigo_de_processamento && <Card flex={1} boxShadow="md">
                        <CardHeader>
                          <Heading size='md'>REF: {registo.num_referencia ? registo.num_referencia : registo.referencia_do_montante}</Heading>
                        </CardHeader>
                      
                        {/* <CardBody>
                          <Stack divider={<StackDivider />} spacing='4'>
                          <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Tipo de registo")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {t(registo.registo_produto)}
                                  </Text>
                                </Box>
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Indicador de produtos")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.produto}
                                  </Text>
                                </Box>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                  {t("Sua entidade")}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.entidade_cliente}
                                </Text>
                              </Box>
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                  {t("Estado")}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                {registo.estado_atm === "Á Processar" ? (
                                    <FcProcess color="black" size={30} />
                                  ) : registo.estado_atm === "Activo" ? (
                                    <HiStatusOnline color="green" size={30} />
                                  ) : (
                                    <HiStatusOffline color="red" size={30} />
                                  )}
                                </Text>
                              </Box>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                  {t("Montante minimo")}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.montante_minimo ? Intl.NumberFormat('PT-br').format(registo.montante_minimo) : <MdClose size={20} color='red'/>}
                                </Text>
                              </Box>
                              <Box>
                                <Heading size='xs' textTransform='uppercase'>
                                  {t("Montante maximo")}
                                </Heading>
                                <Text pt='2' fontSize='sm'>
                                  {registo.montante_maximo ? Intl.NumberFormat('PT-br').format(registo.montante_maximo) : <MdClose size={20} color='red'/>}
                                </Text>
                              </Box>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Data de inicio de pagamento")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.data_inicio_de_pagamento ? new Date(registo.data_inicio_de_pagamento).toLocaleDateString() : <MdClose size={20} color='red'/> }
                                  </Text>
                                </Box>
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Data de limite de pagamento")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.data_limite_pagamento ? new Date(registo.data_limite_pagamento).toLocaleDateString() : <MdClose size={20} color='red'/> }
                                  </Text>
                                </Box>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Código de activação")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.codigo_de_ativacao ? registo.codigo_de_ativacao : <MdClose size={20} color='red'/> }
                                  </Text>
                                </Box>
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Chave de activação")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.chave_ativacao ? registo.chave_ativacao : <MdClose size={20} color='red'/> }
                                  </Text>
                                </Box>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Código de cliente")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.codigo_de_cliente ? registo.codigo_de_cliente : <MdClose size={20} color='red'/> }
                                  </Text>
                                </Box>
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Número serie helpdesk")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.numero_serie_helpDesk ? registo.numero_serie_helpDesk : <MdClose size={20} color='red'/> }
                                  </Text>
                                </Box>
                            </Flex>
                            <Flex direction="row" alignItems="center" justifyContent="space-between">
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Número de linha do talão")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.numero_de_linhas ? registo.numero_de_linhas : <MdClose size={20} color='red'/> }
                                  </Text>
                                </Box>
                                <Box>
                                  <Heading size='xs' textTransform='uppercase'>
                                    {t("Texto para o talão")}
                                  </Heading>
                                  <Text pt='2' fontSize='sm'>
                                    {registo.textos_para_talao ? registo.textos_para_talao : <MdClose size={20} color='red'/> }
                                  </Text>
                                </Box>
                            </Flex>
                            <Box>
                              <Heading size='xs' textTransform='uppercase'>
                                {t("Data de validade")}
                              </Heading>
                              <Text pt='2' fontSize='sm'>
                                {registo.data_de_validade ? new Date(registo.data_de_validade).toLocaleDateString() : <MdClose size={20} color='red'/> }
                              </Text>
                            </Box>
                            {registo.opcional_1 && <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Dado adcional 1")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {String(registo.opcional_1.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_1.split('=')[1])})</b>
                            </Text>
                          </Box>}
                          {registo.opcional_2 && <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Dado adcional 2")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {String(registo.opcional_2.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_2.split('=')[1])})</b>
                            </Text>
                          </Box>}
                          {registo.opcional_3 && <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Dado adcional 3")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {String(registo.opcional_3.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_3.split('=')[1])})</b>
                            </Text>
                          </Box>}
                          </Stack>
                        </CardBody> */}
                        <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                    <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Tipo de registo")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {t(registo.registo_produto == "Pagamento/Carregamento" ? registo.tipo_referencia_pagamento == "P" ? "Pagamentos" : "Carregamentos" : registo.registo_produto)}
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Indicador de produtos")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.produto}
                            </Text>
                          </Box>
                      </Flex>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Sua entidade")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {registo.entidade_cliente}
                        </Text>
                      </Box>
                      {registo.tipo_referencia_pagamento == "I" && <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante minimo")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {registo.montante_minimo ? Intl.NumberFormat('PT-br').format(registo.montante_minimo) : <MdClose size={20} color='red'/>}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante maximo")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {registo.montante_maximo ? Intl.NumberFormat('PT-br').format(registo.montante_maximo) : <MdClose size={20} color='red'/>}
                          </Text>
                        </Box>
                      </Flex>}
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          {registo.tipo_referencia_pagamento == "I" &&  <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Data de inicio de pagamento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.data_inicio_de_pagamento ? new Date(registo.data_inicio_de_pagamento).toLocaleDateString() : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>}
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Data de limite de pagamento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.data_limite_pagamento ? new Date(registo.data_limite_pagamento).toLocaleDateString() : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Hora de limite de pagamento")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.hora_limite_pagamento ? new Date("1970-01-01 "+registo.hora_limite_pagamento).toLocaleTimeString() : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>
                      {(registo.tipo_referencia_pagamento == "P") && (registo.montante_fixo > 99)  && 
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante Fixo")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            AKZ {Intl.NumberFormat('PT-br').format(registo.montante_fixo)}
                          </Text>
                        </Box>
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("ACeite para fixo")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            {registo.aceitar_fixo}
                          </Text>
                        </Box>
                      </Flex>}
                      {(registo.tipo_referencia_pagamento == "P") && (registo.montante_limitado_para_pagamento_longo > 99)  && 
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        <Box>
                          <Heading size='xs' textTransform='uppercase'>
                            {t("Montante Limitado")}
                          </Heading>
                          <Text pt='2' fontSize='sm'>
                            AKZ {Intl.NumberFormat('PT-br').format(registo.montante_limitado_para_pagamento_longo)}
                          </Text>
                        </Box>
                      </Flex>}
                      {registo.tipo_referencia_pagamento == "R" && <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Código de activação")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.codigo_de_ativacao ? registo.codigo_de_ativacao : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Chave de activação")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.chave_ativacao ? registo.chave_ativacao : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>}
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          {registo.tipo_referencia_pagamento == "I" && <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Código de cliente")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.codigo_de_cliente ? registo.codigo_de_cliente : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>}
                          {registo.tipo_referencia_pagamento == "R" && <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Número serie helpdesk")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.numero_serie_helpDesk ? registo.numero_serie_helpDesk : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>}
                      </Flex>
                      {registo.tipo_referencia_pagamento == "I" && <Flex direction="row" alignItems="center" justifyContent="space-between">
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Numeros de linhas")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.numero_de_linhas ? registo.numero_de_linhas : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                          <Box>
                            <Heading size='xs' textTransform='uppercase'>
                              {t("Texto para o talão")}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                              {registo.textos_para_talao ? registo.textos_para_talao : <MdClose size={20} color='red'/> }
                            </Text>
                          </Box>
                      </Flex>}
                      {registo.tipo_referencia_pagamento == "R" && <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Data de validade")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {registo.data_de_validade ? new Date(registo.data_de_validade).toLocaleDateString() : <MdClose size={20} color='red'/> }
                        </Text>
                      </Box>}
                      {registo.opcional_1 && <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Dado adcional 1")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {String(registo.opcional_1.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_1.split('=')[1])})</b>
                        </Text>
                      </Box>}
                      {registo.opcional_2 && <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Dado adcional 2")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {String(registo.opcional_2.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_2.split('=')[1])})</b>
                        </Text>
                      </Box>}
                      {registo.opcional_3 && <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          {t("Dado adcional 3")}
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {String(registo.opcional_3.split('=')[0]).toUpperCase()}<b>({String(registo.opcional_3.split('=')[1])})</b>
                        </Text>
                      </Box>}
                    </Stack>
                  </CardBody>
                  </Card>}
                  {registo?.codigo_de_processamento && <Box flex={1} px={10}>
                  <form onSubmit={formik.handleSubmit}>
                    <Box bg='white' p={10} rounded={5}  boxShadow="md">
                      {(tipo_re.includes("Pagamento/Carregamento") || tipo_re.includes("Facturas")) && <FormControl>
                          <FormLabel>{t("Referência")}</FormLabel>
                          <Flex direction="row" alignItems="center">
                            <Input type='text' disabled onKeyUp={validarDigitos} name='num_referencia' minLength={user.num_padrao_referencias} maxLength={user.num_padrao_referencias} required ref={refREF}
                            onChange={formik.handleChange}
                           value={formik.values.num_referencia} />
                           <Button isDisabled={true} bg="blue.300" color="white" onClick={() => {refREF.current.value = ((Math.random() * 1000) + 1).toString().replace('.', '').substring(0,user.num_padrao_referencias); formik.values.num_referencia = refREF.current.value} } leftIcon={<MdUpdate/>} type='button' >{t("Gerar")}</Button>
                           </Flex>
                      </FormControl>}
                      {/* {registo.tipo_referencia_pagamento == "P" && formik.values.tipo_de_registro == "1" && <FormControl mt={2}>
                        <FormLabel>{t("Montante fixo")}</FormLabel>
                        <Flex direction="row" alignIterems="center">
                          <Input type='number' name='montante_fixo' onKeyUp={validarDigitos} min={100} max={19999999999}
                            onChange={formik.handleChange}
                           value={formik.values.montante_fixo} />
                        </Flex>
                    </FormControl>} */}
                      <FormControl mt={2}>
                          <FormLabel>{t("Código de processamento")}</FormLabel>
                          <Select required name='codigo_de_processamento' onChange={formik.handleChange} value={formik.values.codigo_de_processamento}>
                            <option value="80">{t("Activado")}</option>
                            <option value="82">{t("Desactivado")}</option>
                        </Select>
                      </FormControl>
                      {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                          <FormLabel>{t("Referência do montante")}</FormLabel>
                          <Flex direction="row" alignIterems="center">
                            <Input type='text' name='referencia_do_montante'  required onKeyUp={validarDigitos} ref={refREFMontante} minLength={1} maxLength={5}
                              onChange={formik.handleChange}
                             value={formik.values.referencia_do_montante} />
                             <Button bg="blue.300" color="white" onClick={() => {refREFMontante.current.value = ((Math.random() * 1000) + 1).toString().replace('.', '').substring(0,5); formik.values.referencia_do_montante = refREFMontante.current.value} } leftIcon={<MdUpdate/>} type='button' >{t("Gerar")}</Button>
                          </Flex>
                      </FormControl>}
                      <FormControl mt={2}>
                          <FormLabel>{t("Para qual tipo")}</FormLabel>
                          <Select disabled placeholder='Selecione o tipo de produto' required name='tipo_de_registro' value={formik.values.tipo_de_registro} onChange={(evt) =>{formik.handleChange(evt);onTipoRegisto(evt.target.value)}}>
                          {referencias.map(el => <option value={el.id_tipo_produto}>{el.registo_produto}</option>)}
                          
                        </Select>
                      </FormControl>
                      <FormControl mt={2}>
                          <FormLabel>{t("Indica seu produto")}</FormLabel>
                          <Select disabled placeholder='Selecione o seu produto para referencia' required name='indicador_de_produtos' value={formik.values.indicador_de_produtos} onChange={(evt)=>{formik.handleChange(evt);setIndicador_produto_id(evt.target.options.item(evt.target.options.selectedIndex).getAttribute('id'))}}>
                          {produtos.map(el => <option id={el.cliente_tipo_produto} value={el.codigo_do_produto}>{el.produto}</option>)}
                        </Select>
                      </FormControl>
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                        {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                            <FormLabel>{t("Data de inicio de pagamento")}</FormLabel>
                            <Input type='date' name='data_inicio_de_pagamento'  required
                              onChange={formik.handleChange}
                             value={formik.values.data_inicio_de_pagamento} />
                        </FormControl>}
                        {(tipo_re.includes("Pagamento/Carregamento") || tipo_re.includes("Facturas")) && <FormControl mt={2} ml={1}>
                          <FormLabel>{t("Data limite de pagamento")}</FormLabel>
                          <Input type='date' name='data_limite_pagamento'  required
                            onChange={formik.handleChange}
                            value={formik.values.data_limite_pagamento} />
                      </FormControl>}
                      {tipo_re.includes("Pagamento/Carregamento") && <FormControl mt={2} ml={1}>
                          <FormLabel>{t("Hora limite de pagamento")}</FormLabel>
                          <Input type='time' name='hora_limite_pagamento'
                            onChange={formik.handleChange}
                            value={formik.values.hora_limite_pagamento} />
                      </FormControl>}
                      </Flex>
                      {/* {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                          <FormLabel>Codigo do cliente</FormLabel>
                          <Input type='text' name='codigo_de_cliente' onKeyUp={validarDigitos} minLength={1} maxLength={10} required
                            onChange={formik.handleChange}
                           value={formik.values.codigo_de_cliente} />
                      </FormControl>} */}
                      {(tipo_re.includes("Recargas")) &&  <FormControl mt={2}>
                          <FormLabel>{t("Quantidade de unidades")}</FormLabel>
                          <Input type='text' name='quantidade_de_unidades' onKeyUp={validarDigitos} maxLength={5} minLength={1}  required
                            onChange={formik.handleChange}
                           value={formik.values.quantidade_de_unidades} />
                      </FormControl>}
                      {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                          <FormLabel>{t("Número de serie Helpdesk")}</FormLabel>
                          <Input type='text' name='numero_serie_helpDesk' onKeyUp={validarDigitos} minLength={1} maxLength={10}  required
                            onChange={formik.handleChange}
                           value={formik.values.numero_serie_helpDesk} />
                      </FormControl>}
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                      {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                          <FormLabel>{t("Codigo de activação")}</FormLabel>
                          <Input type='text' onKeyUp={validarDigitos} minLength={1} maxLength={5}  name='codigo_de_ativacao'  required
                            onChange={formik.handleChange}
                           value={formik.values.codigo_de_ativacao} />
                      </FormControl>}
                      {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                          <FormLabel>{t("Chave de activação")}</FormLabel>
                          <Input type='text' name='chave_ativacao' onKeyUp={validarDigitos} minLength={4} maxLength={32}  required
                            onChange={formik.handleChange}
                           value={formik.values.chave_ativacao} />
                      </FormControl>}
                      </Flex>
                      {(tipo_re.includes("Recargas")) && <FormControl mt={2}>
                          <FormLabel>{t("Data de validade")}</FormLabel>
                          <Input type='date' name='data_de_validade'  required
                            onChange={formik.handleChange}
                           value={formik.values.data_de_validade} />
                      </FormControl>}
                      {(tipo_re.includes("Facturas")) &&  <FormControl mt={2}>
                          <FormLabel>{t("Código de cliente")}</FormLabel>
                          <Flex direction="row" alignIterems="center">                          
                            <Input type='text' name='codigo_de_cliente' onKeyUp={validarDigitos} minLength={3} maxLength={10}  required ref={refCodCli}
                              onChange={formik.handleChange}
                             value={formik.values.codigo_de_cliente} />
                             <Button bg="blue.300" color="white" onClick={() => {refCodCli.current.value = ((Math.random() * 1000) + 1).toString().replace('.', '').substring(0,5); formik.values.codigo_de_cliente = refCodCli.current.value} } leftIcon={<MdUpdate/>} type='button' >Gerar</Button>
                          </Flex>
                      </FormControl>}
                      <Flex direction="row" alignItems="center" justifyContent="space-between">
                          {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                              <FormLabel>{t("Montante minimo")}</FormLabel>
                              <Input type='number' name='montante_minimo'  required
                                onChange={formik.handleChange}
                               value={formik.values.montante_minimo} />
                          </FormControl>}
                          {(tipo_re.includes("Facturas")) &&<FormControl mt={2}>
                              <FormLabel>{t("Montante maximo")}</FormLabel>
                              <Input type='number' name='montante_maximo'  required
                                onChange={formik.handleChange}
                               value={formik.values.montante_maximo} />
                          </FormControl>}
                      </Flex>   
                      {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                          <FormLabel>{t("Número de linha do talão")}</FormLabel>
                          <Input type='number' name='numero_de_linhas' min={1} max={5}  required
                            onChange={formik.handleChange}
                           value={formik.values.numero_de_linhas} />
                      </FormControl>}                 
                      {(tipo_re.includes("Facturas")) && <FormControl mt={2}>
                          <FormLabel>Texto do talão</FormLabel>
                          <Textarea type='date' name='textos_para_talao'  required
                            onChange={formik.handleChange}>{formik.values.textos_para_talao}</Textarea>
                      </FormControl>}
                      <Flex mt={2} direction="row" alignItems="center" justifyContent="space-between">
                      <FormControl ml={3}>
                          <FormLabel>{t("Campo Opcional 1")}</FormLabel>
                          <Input ref={refREFOPT1} type='text' name='opcional_1'
                            onChange={formik.handleChange}
                          value={formik.values.opcional_1} />
                      </FormControl>
                      <FormControl ml={3}>
                          <FormLabel>{t("Campo Opcional 2")}</FormLabel>
                          <Input ref={refREFOPT2} type='text' name='opcional_2'
                            onChange={formik.handleChange}
                          value={formik.values.opcional_2} />
                      </FormControl>
                      <FormControl ml={3}>
                          <FormLabel>{t("Campo Opcional 3")}</FormLabel>
                          <Input ref={refREFOPT3} type='text' name='opcional_3'
                            onChange={formik.handleChange}
                          value={formik.values.opcional_3} />
                      </FormControl>
                    </Flex>
                      <Button leftIcon={<MdPeople/>} bg="blue.900" color="white"  type='submit' mt={5}>{t("Actualizar")}</Button>
                   
                  </Box> 
                </form>
                  </Box>}
                  {(registo.length == false) && <Center width="100%">
                      <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
                          <MdError size={200} color="red"/>
                          <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Referência invalida")}</Heading>
                          <Text>{t("Desculpamos pelo transtorno causado, não conseguimos ter informações dos seus dados.")}</Text>
                          
                          <Text as={Link} mt={10} to="/referencias" color='blue.500'>{t("Voltar")}</Text>
                      </Flex>
                    </Center>}
              </Flex>
              :
              <Center>
              {/* <Spinner
                thickness='14px'
                speed='0.5s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
              /> */}
              <Loader />
            </Center>}
            </Box>
        :<Center width="100%">
        <Flex width="100%" flex={4} textAlign="center" color="gray.500" p={10} justifyContent="center" alignItems="center" direction="column">
            <MdError size={200} color="red"/>
            <Heading fontSize={50} color="blackAlpha.800" fontWeight="bold" mb={2}>{t("Acesso não autorizado")}</Heading>
            <Text>{t("Desculpamos pelo transtorno causado, não temos acesso para sí nessa pagina.")}</Text>
        </Flex>
      </Center>}
            <Box mt={5} color="gray.700" textAlign="center">
                  <Text><b>{CONFIG.COMPANY.NAME}</b> * {CONFIG.COMPANY.ADDRESS} * {CONFIG.COMPANY.EMAILS[0]}</Text>
                  <Text>{t("Todos direitos reservados")} &copy; {new Date().getFullYear()}</Text>
            </Box>
        </Flex>
      </Box>
    </Flex>
  </>
  )
}

export default Referencias